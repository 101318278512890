const synonyms: Record<string, string> = {
  'סבון כלים': 'נוזל כלים',
  'נס קפה': 'קפה נמס',
  טיטול: 'חיתול',
  טיטולים: 'חיתולים',
  בטריה: 'סוללה',
  בטריות: 'סוללות',
  'נייר כסף': 'רדיד אלומיניום',
  צהובה: 'גבינה צהובה',
  גבנצ: 'גבינת צהובה',
  לבנה: 'לאבנה',
  פטה: 'גבינת פטה',
  אילנר: 'איילנר',
  איילנייר: 'איילנר',
  קטשוף: 'קטשופ',
  'שמפו כביסה': "ג'ל כביסה",
  'שמפו לכביסה': "ג'ל כביסה",
  'טבליות כביסה': 'קפסולות כביסה',
  'טבליות לכביסה': 'קפסולות לכביסה',
  'עלי פילו': 'בצק פילו',
  'דפי פילו': 'בצק פילו',
  'דפיי פילו': 'בצק פילו',
  אוכל: 'מזון',
  שוואמה: 'שווארמה',
  'מקלות אוזניים': 'קיסמי אוזניים',
  'עלי בוריק': 'עלי בצק',
  'עלי בריק': 'עלי בצק',
  'טיפות אף': 'תרסיס לאף',
  'טיפות לאף': 'תרסיס לאף',
};

const rules = Object.entries(synonyms).map(([from, to]) => ({
  from,
  to,
}));

export function replaceSynonyms(text: string): string {
  return rules.reduce((acc, { from, to }) => acc.replace(from, to), text);
}
