'use client';

import classNames from 'classnames';
import { Link } from '@reshima/navigation-ui';
import { Dropdown } from '@reshima/pure-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { AccountMenuImage } from './account-menu-image';

interface MenuItem {
  label: string;
  path: string;
}

export default function AccountMenu() {
  const name = 'AccountMenu';

  const { ariaLabel, signIn, account, myLists, signOut } =
    useTranslations()['account-menu'];

  const { user, userLoading, isSigningIn } = useClientAuth();

  const menuItems: MenuItem[] = [
    { label: myLists, path: 'lists' },
    ...(!user ? [{ label: signIn, path: 'signin' }] : []),
    ...(user ? [{ label: account, path: 'account' }] : []),
    ...(user?.isSignedIn ? [{ label: signOut, path: 'signout' }] : []),
  ];

  return (
    <Dropdown
      circle
      ghost
      placement="right"
      ariaLabel={ariaLabel}
      disabled={userLoading || isSigningIn}
      dataTestId="account-menu-content"
      container={<AccountMenuImage />}
    >
      <ul>
        {menuItems.map(({ label, path }) => (
          <li key={path}>
            <Link
              key={path}
              href={path}
              className={classNames(
                'block py-2 px-3',
                'rounded-lg',
                'hover:bg-base-200',
              )}
              onClick={() => {
                trackEvent({
                  name,
                  action: Action.Click,
                  actionModifier: ActionModifier.Start,
                  properties: { path, label },
                });
              }}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </Dropdown>
  );
}
