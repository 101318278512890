import { IoHome } from 'react-icons/io5';
import { Button } from '@reshima/pure-ui';
import { List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { isAndroidMessageAvailable } from '@reshima/android-ui';
import { isAndroidWebInterfaceAvailable } from '@reshima/shared-ui';
import { ListShortcutModal } from './list-shortcut-modal';

export function ListShortcutButton({ list }: { list: List }) {
  const name = 'RecategorizeListButton';
  const { button, heading, description } = useTranslations()['list-shortcut'];

  const { showModal } = useModal();

  const properties = { listId: list.id };

  function onClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      description,
      content: <ListShortcutModal list={list} />,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  if (!isAndroidMessageAvailable() && !isAndroidWebInterfaceAvailable()) {
    return null;
  }

  return (
    <Button className="flex items-center gap-2" onClick={onClick} ghost>
      <IoHome className="text-lg" />
      {button}
    </Button>
  );
}
