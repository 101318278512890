import { ReactNode } from 'react';
import { Checkbox } from './checkbox';

export type CheckboxesListOption<T> = {
  id: T;
  label: ReactNode;
  ariaLabel: string;
};

type Props<T> = {
  ids: T[];
  label?: ReactNode;
  options: CheckboxesListOption<T>[];
  buttonClassName?: string;
  onChange: (ids: T[]) => void;
};

export function CheckboxesList<T extends string>({
  ids,
  label,
  options,
  onChange,
}: Props<T>) {
  const idsSet = new Set(ids);

  return (
    <div className="inline-flex flex-col">
      {label}
      {options.map(({ id, label, ariaLabel }) => {
        const checked = idsSet.has(id);
        return (
          <div key={`${id}-${checked}`}>
            <Checkbox
              checked={checked}
              label={label}
              ariaLabel={ariaLabel}
              onChange={() =>
                onChange(
                  checked
                    ? ids.filter((currentId) => currentId !== id)
                    : [...ids, id],
                )
              }
            />
          </div>
        );
      })}
    </div>
  );
}
