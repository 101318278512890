import { HiUsers } from 'react-icons/hi2';
import { List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { ButtonLink } from '@reshima/navigation-ui';

type Props = {
  list: List;
  className?: string;
};

export function ListSharedBadge({ list, className }: Props) {
  const {
    sharedListSingleTooltip,
    sharedListMultipleTooltip,
    sharedListAriaLabel,
  } = useTranslations()['list-tile'];

  if (!list.sharedWith?.length) {
    return null;
  }

  const title =
    list.sharedWith.length === 1
      ? sharedListSingleTooltip
      : sharedListMultipleTooltip.replace(
          '{{participants}}',
          list.sharedWith.length.toString(),
        );

  return (
    <ButtonLink
      href={`/list-members?id=${list.id}`}
      ariaLabel={sharedListAriaLabel}
      className={className}
      circle
      ghost
    >
      <div className="p-1">
        <HiUsers className="text-xl" title={title} />
      </div>
    </ButtonLink>
  );
}
