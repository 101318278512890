'use client';
import { PiShareNetworkFill } from 'react-icons/pi';
import {
  Action,
  trackEvent,
  ActionModifier,
  trackException,
} from '@reshima/telemetry';
import { useTranslations } from '@reshima/translations-ui';
import { Button } from '@reshima/pure-ui';

export type ShareData = {
  title: string;
  url: string;
};

export function ShareButton({ shareData }: { shareData: ShareData }) {
  const name = 'ShareButton';

  const { buttonText } = useTranslations()['share-button'];

  function onClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    try {
      if (window.AndroidWebInterface?.nativeShare) {
        window.AndroidWebInterface?.nativeShare(shareData.title, shareData.url);
      } else {
        navigator.share(shareData);
      }

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
      });
    }
  }

  return (
    <Button
      onClick={onClick}
      className="flex gap-1 items-center justify-center"
    >
      {buttonText}
      <PiShareNetworkFill className="text-2xl" />
    </Button>
  );
}
