const words = [
  'מארז',
  'ארוז',
  'ארוזות',
  'יחידות',
  'יח',
  'קרטון',
  'קרטונים',
  'קרטונית',
  'תפזורת',
  'חבילה',
  'חבילות',
  'חבילת',
  'מכיל',
  'צנצנת',
  'מיכל',
  'וואקום',
  'תערובת',
  'סגירה חוזרת',
  'תפרוזת',
  'בתפזורת',
  'בקבוק',
  'ספריי',
];

export const packagingWords = [...words, ...words.map((word) => `ב${word}`)];
