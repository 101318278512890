'use client';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { isAndroidMessageAvailable } from './is-android-message-available';
import { Message, MessageListener } from './models';
import { isErrorMessage } from './is-message';

const name = 'UseAndroidMessages';

const androidMessageListeners = new Set<MessageListener>();

let isListening = false;
const ensureListeningForAndroidMessages = () => {
  if (isListening) return;
  isListening = true;

  window.androidMessagePort.onmessage = (event) => {
    const action = Action.OnMessage;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    try {
      const message: Message = JSON.parse(event.data);

      if (isErrorMessage(message)) {
        trackException({
          name,
          action,
          error: new Error(message.error),
          start,
        });
      }

      androidMessageListeners.forEach((listener) => listener({ message }));

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
        properties: {
          keys: Object.keys(event),
        },
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
      });
    }
  };
};

export function useAndroidMessages({
  telemetryId,
  onMessage,
}: {
  telemetryId: string;
  onMessage: MessageListener;
}) {
  const onMessageRef = useRef<MessageListener>(null);

  const properties = useMemo(() => ({ telemetryId }), [telemetryId]);

  const onMessageCallback = useCallback(
    ({ message }: { message: Message }) => {
      const action = Action.OnMessage;

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        onMessage({ message });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [onMessage, properties],
  );

  useEffect(() => {
    if (!isAndroidMessageAvailable()) return;

    if (onMessageRef.current !== onMessageCallback) {
      if (onMessageRef.current)
        androidMessageListeners.delete(onMessageRef.current);
      androidMessageListeners.add(onMessageCallback);
      onMessageRef.current = onMessageCallback;

      trackEvent({
        name,
        action: Action.Listen,
        actionModifier: ActionModifier.Start,
        properties,
      });
    }

    ensureListeningForAndroidMessages();

    return () => {
      trackEvent({
        name,
        action: Action.Listen,
        actionModifier: ActionModifier.End,
      });

      androidMessageListeners.delete(onMessageCallback);
    };
  }, [onMessageCallback, properties]);

  return { isAndroidMessageAvailable: isAndroidMessageAvailable() };
}
