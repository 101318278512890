import { IoPlay, IoStop } from 'react-icons/io5';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { List, updateListDisableActivity } from '@reshima/firebase';
import { DisableListActivityModal } from './disable-list-activity-modal';

type Props = {
  list: List;
};

export function ToggleListActivityButton({ list }: Props) {
  const name = 'ToggleListActivityButton';
  const {
    'toggle-list-activity-button': { enable, disable },
    'disable-list-activity-modal': { heading },
  } = useTranslations();

  const { showModal } = useModal();

  const { id: listId, disableActivity } = list;

  const properties = { listId, disableActivity };

  function onToggleButtonClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    if (disableActivity) {
      updateListDisableActivity({ listId, disableActivity: false });
    } else {
      showModal({
        heading,
        content: <DisableListActivityModal listId={listId} />,
      });
    }

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button
      className="flex items-center gap-2"
      onClick={onToggleButtonClick}
      ghost
    >
      {disableActivity ? (
        <>
          <IoPlay className="text-lg" />
          {enable}
        </>
      ) : (
        <>
          <IoStop className="text-lg" />
          {disable}
        </>
      )}
    </Button>
  );
}
