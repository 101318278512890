export const colorsWords = [
  'אדום',
  'אדומה',
  'אדומים',
  'אדומות',
  'אפור',
  'אפורה',
  'אפורים',
  'אפורות',
  'ארגמן',
  'ארגמנים',
  'בורדו',
  'בז',
  'ורוד',
  'ורודה',
  'ורודים',
  'ורודות',
  'חאקי',
  'חאקים',
  'חום',
  'חומה',
  'חומים',
  'חומות',
  'טורקיז',
  'טורקיזים',
  'ירוק',
  'ירוקה',
  'ירוקים',
  'ירוקות',
  'כחול',
  'כחולה',
  'כחולים',
  'כחולות',
  'כתום',
  'כתומה',
  'כתומים',
  'כתומות',
  'לבן',
  'לבנה',
  'לבנים',
  'לבנות',
  'סגול',
  'סגולה',
  'סגולים',
  'סגולות',
  'צהוב',
  'צהובה',
  'צהובים',
  'צהובות',
  // TODO: פלפל שחור
  'תכלת',
  'תכלתים',
];
