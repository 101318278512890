import { Units, trim } from '@reshima/shared';

const unitsMap: Record<Units, Set<string>> = {
  [Units.pcs]: new Set(['יחידות', 'יח']),
  [Units.pks]: new Set(['אריזות', 'אריזה', 'חבילת', 'חבילה', 'חבילות']),
  [Units.g]: new Set(['גרמים', 'גרם', 'גר']),
  [Units.kg]: new Set(['קילוגרמים', 'קילו', 'קילוגרם', 'קג']),
  [Units.l]: new Set(['ליטרים', 'ליטר', 'לי']),
  [Units.ml]: new Set(['מיליליטרים', 'מיליליטר', 'מל']),
};

const units = Object.entries(unitsMap) as [Units, Set<string>][];

export function extractMeasurementWords({ text }: { text: string }): Units[] {
  const words = text
    .split(' ')
    .map((w) => w.replace(/'|"/g, ''))
    .map(trim)
    .filter(Boolean);

  return units
    .filter(([, unitWords]) => words.some((word) => unitWords.has(word)))
    .map(([unit]) => unit);
}

export function removeMeasurementWords(text: string): string {
  const words = text.split(' ').map(trim);

  return words
    .filter((word) => !units.some(([, unitWords]) => unitWords.has(word)))
    .join(' ');
}
