'use client';
import { useCallback, useEffect, useState } from 'react';
import { ProviderId } from '@reshima/shared';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { signInWithCredential } from '@reshima/firebase';
import { isErrorMessage } from '@reshima/messages';
import { isAndroidWebInterfaceAvailable } from '@reshima/shared-ui';
import { isSignInResponseTokenMessage } from './is';

declare global {
  interface Window {
    AndroidSignInCallback?: (message: string) => Promise<void>;
  }
}

export function useAndroidAuth() {
  const name = 'UseAndroidAuth';
  const action = Action.SignIn;

  const [isAndroidSignInError, setIsAndroidSignInError] = useState(false);
  const [isAndroidSigningIn, setIsAndroidSigningIn] = useState(false);
  const [androidSigningProviderId, setAndroidSigningProviderId] =
    useState<ProviderId>();

  const onMessage = useCallback(
    async (message: string) => {
      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
      });

      try {
        if (isErrorMessage(message)) {
          throw new Error(message.error?.toString());
        }

        if (!isSignInResponseTokenMessage(message)) {
          throw new Error('Invalid message received');
        }

        await signInWithCredential(message);

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties: { providerId: message.providerId },
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          start,
        });
        setIsAndroidSignInError(true);
      } finally {
        setIsAndroidSigningIn(false);
        setAndroidSigningProviderId(undefined);
      }
    },
    [action],
  );

  const androidSignIn = useCallback(
    ({ providerId }: { providerId: ProviderId }) => {
      setIsAndroidSigningIn(true);
      setAndroidSigningProviderId(providerId);
      setIsAndroidSignInError(false);

      const properties = { providerId };

      const start = trackEvent({
        name,
        action: Action.SignInRequest,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        if (!window.AndroidSignInCallback) {
          throw new Error('AndroidSignInCallback is not available');
        }

        window.AndroidWebInterface.signIn(providerId);

        trackEvent({
          name,
          action: Action.SignInRequest,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        setIsAndroidSigningIn(false);
        setAndroidSigningProviderId(undefined);
        setIsAndroidSignInError(true);
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
        throw error;
      }
    },
    [action],
  );

  useEffect(() => {
    window.AndroidSignInCallback = onMessage;

    return () => {
      window.AndroidSignInCallback = undefined;
    };
  }, [onMessage]);

  return {
    isAndroidSigningIn,
    isAndroidSignInError,
    isAndroidSignInAvailable: isAndroidWebInterfaceAvailable(),
    androidSigningProviderId,
    androidSignIn,
  };
}
