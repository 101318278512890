import { ReactNode } from 'react';
import { useTranslations } from '@reshima/translations-ui';
import { Placement, Select, SelectOption } from '@reshima/pure-ui';
import { CategoryId, getCategoryIcon, itemCategories } from '@reshima/category';

type EmptyOption = {
  label: string;
  icon: string;
};

function LongLabel({
  icon,
  children,
}: {
  icon: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className="flex flex-1 items-center gap-2 cursor-pointer">
      <span className="text-xl">{icon}</span>
      <span>{children}</span>
    </div>
  );
}

type Props = {
  categoryId?: CategoryId;
  className?: string;
  placeholder?: ReactNode;
  round?: boolean;
  emptyOption?: EmptyOption;
  placement: Placement;
  showText?: boolean;
  chevron?: boolean;
  onCategoryIdChange: (categoryId: CategoryId) => void;
};

export function CategoriesSelect({
  categoryId,
  className,
  placeholder,
  round = true,
  chevron = true,
  placement,
  showText,
  emptyOption,
  onCategoryIdChange,
}: Props) {
  const dictionary = useTranslations();

  const { ariaLabel, searchPlaceholder, noResultsMessage } =
    dictionary['categories-picker'];

  const options: SelectOption<CategoryId>[] = [];

  if (emptyOption) {
    // TODO: Infrastucture should provide a way to pass undefined as a value
    options.push({
      value: undefined as unknown as CategoryId,
      longLabel: (
        <LongLabel icon={emptyOption.icon}>{emptyOption.label}</LongLabel>
      ),
    });
  }

  options.push(
    ...itemCategories.map(({ id }) => {
      const icon = getCategoryIcon(id);
      const text = dictionary.categories[id];

      return {
        value: id,
        text,
        shortLabel: showText ? (
          <LongLabel icon={icon}>{text}</LongLabel>
        ) : (
          <span className="text-xl">{icon}</span>
        ),
        longLabel: <LongLabel icon={icon}>{text}</LongLabel>,
      };
    }),
  );

  return (
    <Select
      ariaLabel={ariaLabel}
      value={categoryId}
      placeholder={placeholder}
      onChange={(value) => onCategoryIdChange(value as CategoryId)}
      options={options}
      chevron={chevron}
      round={round}
      placement={placement}
      searchPlaceholder={searchPlaceholder}
      noResultsMessage={noResultsMessage}
      buttonClassName={className}
      contentClassName="max-w-[calc(100vw-1.5rem)]"
      optionsClassName="text-base max-h-56 overflow-y-auto"
      search
      ghost
      tight
    />
  );
}
