import classNames from 'classnames';
import { List } from '@reshima/firebase';
import { SortingDropdown } from './sorting-dropdown';
import { SortingDirection } from './sorting-direction';

type Props = {
  list: List;
  className?: string;
};

export function ListSortingButton({ list, className }: Props) {
  return (
    <div className={classNames('flex items-stretch', className)}>
      <SortingDirection list={list} />
      <SortingDropdown list={list} placement="right" />
    </div>
  );
}
