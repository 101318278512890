'use client';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import {
  AnimatePresence,
  Reorder,
  useDragControls,
  useMotionValue,
} from 'framer-motion';
import classNames from 'classnames';
import { RxCaretSort } from 'react-icons/rx';
import {
  List as IList,
  getListsStream,
  getManualSortedLists,
  updateListsOrder,
} from '@reshima/firebase';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useClientAuth } from '@reshima/client-auth-ui';
import { useTranslations } from '@reshima/translations-ui';
import { useManagedSyncStatus } from '@reshima/nav-bar-ui';
import { ListTile } from './list-tile';
import { CreateListButton } from './create-list-button';
import { GettingStartedButton } from './getting-started-button';

function List({
  list,
  showDragHandle,
}: {
  list: IList;
  showDragHandle: boolean;
}) {
  const y = useMotionValue(0);
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      value={list}
      key={list.id}
      style={{ y }}
      dragListener={false}
      dragControls={dragControls}
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.8, opacity: 0 }}
    >
      <div
        className={classNames(
          'flex items-center',
          'bg-base-100',
          'border border-base-300 rounded-md shadow-md',
          { 'ps-2 xs:ps-3': !showDragHandle },
        )}
      >
        {showDragHandle && (
          <div
            className="cursor-grab touch-none select-none p-1 xs:p-2"
            onPointerDown={(event) => {
              event.preventDefault();
              dragControls.start(event);
            }}
          >
            <RxCaretSort className="text-2xl" />
          </div>
        )}
        <ListTile list={list} className="flex-1" />
      </div>
    </Reorder.Item>
  );
}

export function Lists() {
  const name = 'Lists';
  const [lists, setLists] = useState<IList[]>();
  const [isLoadingLists, setIsLoadingLists] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const { userLoading, isSigningIn, user, userData, isMigratingUserData } =
    useClientAuth();
  const {
    lists: { noLists, error },
    'list-text': { defaultListName },
  } = useTranslations();
  const { setSyncByStreamSource, setSyncGuest } = useManagedSyncStatus();

  const fetchListsData = useCallback(
    ({ userId }: { userId: string }) => {
      const action = 'ListsData';

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
      });

      return getListsStream({
        userId,
        defaultListName,
        onUpdate: ({ lists, source }) => {
          setIsLoadingLists(false);
          setIsError(false);
          setLists(lists);
          setSyncByStreamSource(source);
          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            properties: { listsCount: lists.length },
          });
        },
        onError: (error) => {
          setIsLoadingLists(false);
          setIsError(true);
          trackException({
            name,
            action,
            error,
          });
        },
      });
    },
    [defaultListName, setSyncByStreamSource],
  );

  const onReorder = useCallback(
    async ({ userId, lists }: { userId: string; lists: IList[] }) => {
      const action = Action.Sort;

      const properties = {
        userId: userId,
        listsLength: lists?.length,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        const listsOrder = lists.map(({ id }) => id);

        await updateListsOrder({
          userId,
          listsOrder,
        });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [],
  );

  useEffect(() => {
    if (isSigningIn || userLoading || isMigratingUserData) return;

    if (!user) {
      setIsGuest(true);
      setIsLoadingLists(false);
      setSyncGuest();
      return;
    }

    return fetchListsData({ userId: user.firebaseUser.uid });
  }, [
    fetchListsData,
    isMigratingUserData,
    isSigningIn,
    setSyncGuest,
    user,
    userLoading,
  ]);

  if (isLoadingLists || !lists || !userData) {
    return (
      <div className="flex flex-col items-center" data-testid="loading">
        <AiOutlineLoading className="text-3xl animate-spin" />
      </div>
    );
  }

  if (isError) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (isGuest) {
    return (
      <div className="flex flex-col items-center">
        <GettingStartedButton />
      </div>
    );
  }

  const sortedLists = getManualSortedLists({
    listsOrder: userData.listsOrder,
    lists,
  });

  return (
    <div className="max-w-xs w-full mx-auto flex flex-col items-center gap-4">
      {!sortedLists.length ? (
        <div className="text-center">{noLists}</div>
      ) : (
        <AnimatePresence>
          <Reorder.Group
            axis="y"
            values={sortedLists}
            onReorder={(lists) => onReorder({ userId: userData.id, lists })}
          >
            <div className="flex flex-col items-strech gap-4">
              {sortedLists.map((list) => (
                <List
                  key={list.id}
                  list={list}
                  showDragHandle={sortedLists.length > 1}
                />
              ))}
            </div>
          </Reorder.Group>
        </AnimatePresence>
      )}
      <CreateListButton />
    </div>
  );
}
