import { ReshimaPage } from '@reshima/shared-ui';
import { AndroidSignIn } from '../android/android-signin';

export function AndroidSignInPage() {
  return (
    <ReshimaPage
      name="AndroidSignInPage"
      className="justify-center items-center"
    >
      <AndroidSignIn />
    </ReshimaPage>
  );
}
