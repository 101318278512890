'use client';
import {
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { MotionDiv } from '@reshima/shared-ui';
import { Button } from './button';
import { Portal } from './portal';

export type DropdownRef = {
  open: () => void;
  close: () => void;
};

export type Placement = 'right' | 'left';

type Props = PropsWithChildren<{
  container: ReactNode;
  ariaLabel: string;
  round?: boolean;
  circle?: boolean;
  ghost?: boolean;
  tight?: boolean;
  buttonClassName?: string;
  containerClassName?: string;
  disabled?: boolean;
  secondary?: boolean;
  portal?: boolean;
  dataTestId?: string;
  placement: Placement;
}>;

export const Dropdown = forwardRef<DropdownRef, Props>(function Dropdown(
  {
    container,
    ariaLabel,
    round = true,
    circle,
    ghost,
    tight,
    buttonClassName,
    containerClassName,
    disabled,
    secondary,
    placement,
    dataTestId,
    children,
    portal = false,
  }: Props,
  ref,
) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        close();
      }
    }

    function onKeydown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        close();
      }
    }

    window.addEventListener('click', onClick);
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('click', onClick);
      window.removeEventListener('keydown', onKeydown);
    };
  }, [close]);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  function Content({
    className,
    style,
    children,
  }: PropsWithChildren<{ className?: string; style?: CSSProperties }>) {
    return (
      <MotionDiv
        onClick={close}
        data-testid={dataTestId}
        initial={{
          opacity: 0,
          y: -10,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        exit={{
          opacity: 0,
          y: -10,
        }}
        className={classNames(
          'absolute top-full z-40',
          'w-max p-1',
          'bg-base-100 border border-base-300 rounded-md shadow-md',
          className,
        )}
        style={style}
      >
        {children}
      </MotionDiv>
    );
  }

  function NonPortalContent({ children }: PropsWithChildren) {
    return (
      <Content className={placement === 'left' ? 'right-0' : 'left-0'}>
        {children}
      </Content>
    );
  }

  function PortalContent({ children }: PropsWithChildren) {
    const buttonElement = buttonRef.current;

    if (!buttonElement) {
      return <NonPortalContent>{children}</NonPortalContent>;
    }

    const buttonRect = buttonElement.getBoundingClientRect();

    const top = buttonRect.y + window.scrollY + buttonRect.height;
    const right =
      document.documentElement.scrollWidth - buttonRect.x - buttonRect.width;
    const left = buttonRect.x + window.scrollX;

    const horizontal: { left: number } | { right: number } =
      placement === 'left' ? { right } : { left };

    return (
      <Portal>
        <Content
          style={{
            ...horizontal,
            top,
          }}
        >
          {children}
        </Content>
      </Portal>
    );
  }

  return (
    <div
      ref={containerRef}
      className={classNames('flex flex-col relative', containerClassName)}
    >
      <Button
        ref={buttonRef}
        onClick={() => (isOpen ? close() : open())}
        ariaLabel={ariaLabel}
        className={buttonClassName}
        round={round}
        circle={circle}
        ghost={ghost}
        tight={tight}
        disabled={disabled}
        secondary={secondary}
      >
        {container}
      </Button>
      <AnimatePresence>
        {isOpen &&
          (portal ? (
            <PortalContent>{children}</PortalContent>
          ) : (
            <NonPortalContent>{children}</NonPortalContent>
          ))}
      </AnimatePresence>
    </div>
  );
});
