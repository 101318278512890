import classNames from 'classnames';

type Props = {
  date: number;
  className?: string;
  name?: string;
  min?: string;
  max?: string;
  onChange: (date: number) => void;
};

export function DateInput({
  date,
  className,
  name,
  min,
  max,
  onChange,
}: Props) {
  return (
    <input
      type="date"
      name={name}
      min={min}
      max={max}
      className={classNames(
        'py-1 px-1',
        'border border-base-300 rounded-md',
        'bg-base-100',
        'dark:[color-scheme:dark]',
        className,
      )}
      value={date ? new Date(date).toISOString().split('T')[0] : ''}
      onChange={(event) => {
        const value = event.target.value;
        onChange(new Date(value).getTime());
      }}
    />
  );
}
