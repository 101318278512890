import { UserMessage } from './models';

const userMessagesPrefixKey = 'userMessages';

type UserMessageId = 'referral';

type EncodedUserMessage = Omit<UserMessage, 'hiddenAt'> & {
  hiddenAt: number;
};

function getUserMessagesKey({
  messageId,
}: {
  messageId: UserMessageId;
}): string {
  return `${userMessagesPrefixKey}:${messageId}`;
}

function parseUserMessage(
  stringifiedUserMessage?: string | null,
): UserMessage | undefined {
  if (!stringifiedUserMessage) {
    return undefined;
  }

  try {
    const { hiddenAt } = JSON.parse(
      stringifiedUserMessage,
    ) as EncodedUserMessage;

    if (!hiddenAt) {
      return undefined;
    }

    if (typeof hiddenAt !== 'number') {
      return undefined;
    }

    return {
      hiddenAt: new Date(hiddenAt),
    };
  } catch {
    console.error('Failed to parse user message');
    return undefined;
  }
}

export function getUserMessage({
  messageId,
}: {
  messageId: UserMessageId;
}): UserMessage | undefined {
  const stringifiedUserMessage = localStorage.getItem(
    getUserMessagesKey({ messageId }),
  );

  return parseUserMessage(stringifiedUserMessage);
}

export function setUserMessage({
  messageId,
  userMessage,
}: {
  messageId: UserMessageId;
  userMessage: UserMessage;
}): void {
  const { hiddenAt } = userMessage;

  const encodedUserMessage: EncodedUserMessage = {
    hiddenAt: hiddenAt.getTime(),
  };

  localStorage.setItem(
    getUserMessagesKey({ messageId }),
    JSON.stringify(encodedUserMessage),
  );
}
