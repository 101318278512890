import { AiOutlineDelete } from 'react-icons/ai';
import { Button } from '@reshima/pure-ui';
import { Item, List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { DeleteListItemsModal } from './delete-list-items-modal';
import { useListContext } from './list-context';

export function DeleteListItemsButton({
  list,
  items,
}: {
  list: List;
  items: Item[];
}) {
  const name = 'DeleteListButton';
  const { button, heading, description } =
    useTranslations()['delete-list-items'];

  const { showModal } = useModal();
  const { addTask } = useListContext();

  const properties = { listId: list.id };

  function onDeleteButtonClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      description,
      content: (
        <DeleteListItemsModal list={list} items={items} addTask={addTask} />
      ),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button
      className="flex items-center gap-2"
      onClick={onDeleteButtonClick}
      disabled={!items.length}
      ghost
    >
      <AiOutlineDelete className="text-lg" />
      {button}
    </Button>
  );
}
