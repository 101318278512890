import { Dictionary } from '@reshima/translations';
import { useTranslations } from '@reshima/translations-ui';
import { Button } from '@reshima/pure-ui';
import { useListContext } from '../lists/list-context';

function getItemsTitle({
  itemsLength,
  dictionary,
}: {
  itemsLength: number;
  dictionary: Dictionary;
}) {
  const { itemTitle, itemsTitle, noItemsTitle } = dictionary['list'];

  if (itemsLength === 0) {
    return noItemsTitle;
  }

  if (itemsLength === 1) {
    return itemTitle;
  }

  return `${itemsLength} ${itemsTitle}`;
}

export function ListItemsDetailsButton({
  itemsLength,
}: {
  itemsLength: number;
}) {
  const dictionary = useTranslations();
  const { toggleShowingItemDetails } = useListContext();

  return (
    <Button
      className="text-sm px-1 2xs:px-1.5 xs:px-2 py-1"
      onClick={toggleShowingItemDetails}
      ghost
      tight
    >
      {getItemsTitle({ itemsLength, dictionary })}
    </Button>
  );
}
