import { split } from '@reshima/shared';

export function removePrefixLetter({
  word,
  letter,
  inflectionsMap,
  wordsMap,
}: {
  word: string;
  letter: string;
  inflectionsMap: Record<string, unknown>;
  wordsMap: Record<string, unknown>;
}): string {
  const words = split(word);

  const result = words
    .map((word, index) => {
      if (index === 0) {
        return word;
      }

      if (word in inflectionsMap || word in wordsMap) {
        return word;
      }

      return word.replace(new RegExp(`^${letter}`), '');
    })
    .join(' ');

  return result;
}
