import { Units } from '@reshima/shared';
import { useTranslations } from '@reshima/translations-ui';
import { NativeSelect } from '@reshima/pure-ui';

export function UnitSelect({
  unit,
  className,
  buttonClassName,
  optionClassName,
  tight,
  chevron,
  onChange,
}: {
  unit: Units;
  className?: string;
  buttonClassName?: string;
  optionClassName?: string;
  tight?: boolean;
  chevron?: boolean;
  onChange: (unit: Units) => void;
}) {
  const { ariaLabel, units } = useTranslations()['units-select'];

  const options = Object.values(Units).map((key) => ({
    value: key,
    shortLabel: units[key].short,
    longLabel: units[key].long,
  }));

  return (
    <NativeSelect
      ghost
      ariaLabel={ariaLabel}
      tight={tight}
      className={className}
      buttonClassName={buttonClassName}
      optionClassName={optionClassName}
      value={unit}
      onChange={(value) => onChange(value as Units)}
      options={options}
      chevron={chevron}
    />
  );
}
