/* eslint-disable @typescript-eslint/no-empty-function */
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ItemActivityType,
  itemToNewListItemActivity,
  NewListItemActivityV2,
} from '@reshima/list-activity-shared';
import { Item, List } from '@reshima/firebase';
import {
  postTasksActivities,
  resetCurrentSession,
  setCurrentActivitiesInLocalStorage,
} from '@reshima/list-activity-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { RevertAbleTask } from '../reverting/models';

type TaskActivity = {
  type: ItemActivityType;
  items: Item[];
  createdAt: number;
};

type Task = RevertAbleTask & {
  activity?: TaskActivity;
};

type NewTask = RevertAbleTask & {
  activity?: Omit<TaskActivity, 'createdAt'>;
};

export type ListContext = {
  maxItemsCount: number;
  showingItemDetails: boolean;
  containsRedoTasks: boolean;
  containsUndoTasks: boolean;
  addTask: (args: NewTask) => void;
  onRedo: () => void;
  onUndo: () => void;
  toggleShowingItemDetails: () => void;
  onItemChecked: () => void;
};

export const listContext = createContext<ListContext>({
  maxItemsCount: 0,
  showingItemDetails: false,
  containsRedoTasks: false,
  containsUndoTasks: false,
  addTask: () => {},
  onRedo: () => {},
  onUndo: () => {},
  toggleShowingItemDetails: () => {},
  onItemChecked: () => {},
});

export function useListContext(): ListContext {
  return useContext(listContext);
}

function isTaskActivity(task: Task): task is Task & { activity: TaskActivity } {
  return task.activity !== undefined;
}

function tasksActivitiesToNewListItemActivities({
  tasks,
}: {
  tasks: Task[];
}): NewListItemActivityV2[] {
  const tasksActivities = tasks
    .filter(isTaskActivity)
    .map(({ activity }) => activity);

  return tasksActivities
    .map(({ type, items, createdAt }) =>
      items.map((item) =>
        itemToNewListItemActivity({
          item,
          type,
          createdAt,
        }),
      ),
    )
    .flat();
}

export function ListContextProvider({
  list,
  maxItemsCount,
  onItemChecked,
  children,
}: PropsWithChildren<{
  list: List;
  maxItemsCount: number;
  onItemChecked: () => void;
}>) {
  const [redoTasks, setRedoTasks] = useState<Task[]>([]);
  const [undoTasks, _setUndoTasks] = useState<Task[]>([]);
  const [showingItemDetails, setShowingItemDetails] = useState(false);
  const { user } = useClientAuth();

  const setUndoTasks = useCallback(
    (tasks: Task[]) => {
      _setUndoTasks(tasks);
      if (!list.disableActivity) {
        const activities = tasksActivitiesToNewListItemActivities({
          tasks,
        });

        setCurrentActivitiesInLocalStorage({ listId: list.id, activities });
      }
    },
    [list.disableActivity, list.id],
  );

  const addTask = useCallback(
    (task: NewTask) => {
      const enhancedTask: Task = {
        ...task,
        activity: task.activity && {
          ...task.activity,
          createdAt: Date.now(),
        },
      };

      const tasks = [enhancedTask, ...undoTasks];
      setUndoTasks(tasks);
      setRedoTasks([]);
    },
    [setUndoTasks, undoTasks],
  );

  const onRedo = useCallback(() => {
    const [task, ...updatedRedoTasks] = redoTasks;
    if (task) {
      task.redo();
      setRedoTasks(updatedRedoTasks);
      const updatedUndoTasks = [task, ...undoTasks];
      setUndoTasks(updatedUndoTasks);
    }
  }, [redoTasks, setUndoTasks, undoTasks]);

  const onUndo = useCallback(() => {
    const [task, ...updatedUndoTasks] = undoTasks;
    if (task) {
      task.undo();
      setUndoTasks(updatedUndoTasks);
      setRedoTasks([task, ...redoTasks]);
    }
  }, [redoTasks, setUndoTasks, undoTasks]);

  const toggleShowingItemDetails = useCallback(
    () => setShowingItemDetails((prev) => !prev),
    [],
  );

  const _postTasksActivities = useCallback(async () => {
    if (!user) return;

    postTasksActivities({
      user,
    });
  }, [user]);

  useEffect(() => {
    _postTasksActivities();

    return () => {
      resetCurrentSession();
      _postTasksActivities();
    };
  }, [_postTasksActivities]);

  return (
    <listContext.Provider
      value={{
        maxItemsCount,
        showingItemDetails,
        containsRedoTasks: redoTasks.length > 0,
        containsUndoTasks: undoTasks.length > 0,
        addTask,
        onRedo,
        onUndo,
        toggleShowingItemDetails,
        onItemChecked,
      }}
    >
      {children}
    </listContext.Provider>
  );
}
