import { useCallback, useEffect, useRef } from 'react';
import { useIsOnline } from '@reshima/shared-ui';
import { categoryItem, Item, List } from '@reshima/firebase';
import { categoriesMap } from '@reshima/category';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';

type UpdatesCounts = Record<string, number>;

const name = 'useUpdateItemsLoadingCategories';

export function useUpdateItemsLoadingCategories({
  list,
  items,
}: {
  list?: List;
  items?: Item[];
}) {
  const updatesCounts = useRef<UpdatesCounts>({});
  const { isOnline } = useIsOnline();

  const updateItemsLoadingCategories = useCallback(
    async ({ list, items }: { list: List; items: Item[] }) => {
      const action = 'UpdateItemsLoadingCategories';

      const { id: listId } = list;
      const properties = { listId };

      const loadingItems = items.filter(
        ({ id, categoryId }) =>
          categoryId === categoriesMap.Loading.id &&
          (updatesCounts.current[id] || 0) < 3,
      );

      if (!loadingItems.length) {
        return;
      }

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        for (const item of loadingItems) {
          updatesCounts.current[item.id] =
            (updatesCounts.current[item.id] || 0) + 1;
          await categoryItem({ list, itemId: item.id, itemName: item.name });
        }

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties: {
            ...properties,
            itemsCount: loadingItems.length,
          },
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
        throw error;
      }
    },
    [],
  );

  useEffect(() => {
    if (isOnline && list && items) {
      updateItemsLoadingCategories({ list, items });
    }
  }, [isOnline, list, items, updateItemsLoadingCategories]);
}
