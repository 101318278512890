export const sizingWords = [
  'קטן',
  'קטנה',
  'קטנים',
  'קטנות',
  'בינוני',
  'בינונית',
  'בינוניים',
  'בינוניות',
  'גדולים',
  'גדולות',
  'גדול',
  'גדולה',
];
