'use client';
import { useCallback, useEffect } from 'react';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { MessageListener, useAndroidMessages } from '@reshima/android-ui';
import { AppUser, getCustomToken } from '@reshima/firebase';
import {
  isSignInWithCustomTokenResponseMessage,
  isSignOutResponseMessage,
} from './is';
import {
  SignInWithCustomTokenRequestMessage,
  SignInWithCustomTokenResponseMessage,
  SignOutRequestMessage,
  SignOutResponseMessage,
} from './models';

const actionsMap: Record<
  | SignInWithCustomTokenResponseMessage['action']
  | SignOutResponseMessage['action'],
  Action
> = {
  signInWithCustomToken: Action.SignInResponse,
  signOut: Action.SignOutResponse,
};

export function useTWAToAndroidAuthSync({
  user,
  userLoading,
}: {
  user?: AppUser;
  userLoading: boolean;
}) {
  const name = 'UseTWAToAndroidAuthSync';

  const onMessage: MessageListener = useCallback(async ({ message }) => {
    if (
      !isSignInWithCustomTokenResponseMessage(message) &&
      !isSignOutResponseMessage(message)
    )
      return;

    const action = actionsMap[message.action];

    const { success } = message;

    if (success) {
      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
      });
    } else {
      trackException({
        name,
        action,
        error: new Error(`Failed to ${message.action}`),
      });
    }
  }, []);

  const postCustomTokenToApp = useCallback(async () => {
    const action = Action.SignInRequest;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    try {
      const customToken = await getCustomToken();

      const message: SignInWithCustomTokenRequestMessage = {
        action: 'signInWithCustomToken',
        customToken,
      };

      window.androidMessagePort.postMessage(JSON.stringify(message));

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
        properties: {
          customTokenLength: customToken.length,
        },
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
      });
    }
  }, []);

  const postSignOutToApp = useCallback(() => {
    const start = trackEvent({
      name,
      action: Action.SignOut,
      actionModifier: ActionModifier.Start,
    });

    const message: SignOutRequestMessage = {
      action: 'signOut',
    };

    try {
      window.androidMessagePort.postMessage(JSON.stringify(message));

      trackEvent({
        name,
        action: Action.SignOut,
        actionModifier: ActionModifier.End,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action: Action.SignOut,
        error,
        start,
      });
    }
  }, []);

  const { isAndroidMessageAvailable: isTWA } = useAndroidMessages({
    telemetryId: 'twa-toandroid-auth-sync',
    onMessage,
  });

  useEffect(() => {
    if (isTWA && !userLoading) {
      if (user) {
        postCustomTokenToApp();
      } else {
        postSignOutToApp();
      }
    }
  }, [isTWA, postCustomTokenToApp, postSignOutToApp, user, userLoading]);
}
