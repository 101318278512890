import { useCallback } from 'react';
import classNames from 'classnames';
import { RxCaretSort } from 'react-icons/rx';
import {
  AnimatePresence,
  Reorder,
  useDragControls,
  useMotionValue,
} from 'framer-motion';
import {
  Item as IItem,
  List,
  defaultListSortByDirection,
  getListManualSortedItems,
  updateListItemsOrder,
} from '@reshima/firebase';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { ListItem } from './list-item';

function Item({ list, item }: { list: List; item: IItem }) {
  const y = useMotionValue(0);
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      value={item}
      key={item.id}
      style={{ y }}
      dragListener={false}
      dragControls={dragControls}
      layout="position"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
    >
      <div
        className={classNames(
          'flex items-center',
          'py-1',
          'bg-base-100',
          'border-b border-base-300 last:border-b-0',
        )}
      >
        <div
          className="cursor-grab touch-none select-none p-2"
          onPointerDown={(event) => {
            event.preventDefault();
            dragControls.start(event);
          }}
        >
          <RxCaretSort className="text-xl" />
        </div>
        <ListItem list={list} item={item} className="flex-1" />
      </div>
    </Reorder.Item>
  );
}

export function ListItemsTableManual({
  list,
  items,
}: {
  list: List;
  items: IItem[];
}) {
  const name = 'ListItemsTableManual';

  const sortedItems = getListManualSortedItems({ list, items });

  if (list.sortByDirection !== defaultListSortByDirection) {
    sortedItems.reverse();
  }

  const onItemSorting = useCallback(
    async (items: IItem[]) => {
      const action = Action.Sort;

      const properties = {
        listId: list.id,
        itemsLength: items?.length,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        const itemsOrder = items.map(({ id }) => id);

        if (list.sortByDirection !== defaultListSortByDirection) {
          itemsOrder.reverse();
        }

        await updateListItemsOrder({
          listId: list.id,
          itemsOrder,
        });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [list],
  );

  return (
    <AnimatePresence>
      <Reorder.Group axis="y" values={sortedItems} onReorder={onItemSorting}>
        <div className="flex flex-col">
          {sortedItems.map((item) => (
            <Item key={item.id} list={list} item={item} />
          ))}
        </div>
      </Reorder.Group>
    </AnimatePresence>
  );
}
