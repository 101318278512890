import { Suspense } from 'react';
import { Dictionary } from '@reshima/translations';
import { ReshimaPage } from '@reshima/shared-ui';
import { ListParticipants } from '../lib/list-participants';

export function ListParticipantsPage({
  dictionary,
}: {
  dictionary: Dictionary;
}) {
  const { heading, description, previousPageAriaLabel } =
    dictionary['list-participants'];

  return (
    <ReshimaPage
      name="ListParticipantsPage"
      heading={heading}
      description={description}
      previousPage={{
        href: '/list',
        ariaLabel: previousPageAriaLabel,
        keepSearch: true,
      }}
    >
      <Suspense>
        <ListParticipants />
      </Suspense>
    </ReshimaPage>
  );
}
