import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { debouncedCallback } from '@reshima/shared';
import {
  Item,
  List,
  maxItemNameLength,
  suggestCategorizedItem,
  updateItemCategory,
  updateItemName,
} from '@reshima/firebase';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { DebouncedInput } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { ListItemCountsUnits } from './list-item-counts-units';

type Props = {
  list: List;
  item: Item;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
};

export function ListItemText({
  list,
  item,
  className,
  onFocus,
  onBlur,
}: Props) {
  const name = 'ListItemText';

  const { id: itemId, name: itemName } = item;

  const { ariaLabel } = useTranslations()['list-item-text'];
  const [isEditing, setIsEditing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // TODO: fix this
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCategory = useCallback(
    debouncedCallback<string>({
      callback: async (itemName: string) => {
        const action = 'UpdateCategory';

        const properties = {
          listId: list.id,
          itemId,
          itemName,
        };

        const start = trackEvent({
          name,
          action,
          actionModifier: ActionModifier.Start,
          properties,
        });

        try {
          const categoryId = await suggestCategorizedItem({ itemName });

          await updateItemCategory({ list, itemId, categoryId });

          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            properties,
            start,
          });
        } catch (error) {
          trackException({
            name,
            action,
            error,
            properties,
            start,
          });
        }
      },
      delay: 300,
    }),
    [],
  );

  async function onNameChanged(newName: string) {
    const action = Action.Rename;

    const properties = {
      listId: list.id,
      itemId,
      itemName,
      newName,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      updateItemName({ list, itemId, itemName: newName });

      debouncedUpdateCategory(newName);

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (!containerRef.current?.contains(e.target as Node)) {
        setIsEditing(false);
      }
    }

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, []);

  const disableBorders =
    'border-transparent focus:border-transparent focus:ring-0';

  return (
    <div
      ref={containerRef}
      className={classNames(
        className,
        'flex',
        'flex-col items-start justify-center',
        '2xs:flex-row 2xs:items-stretch 2xs:justify-start',
        'text-lg',
        'rounded-md',
        'focus-within:ring-2 focus-within:ring-blue-500',
      )}
    >
      <DebouncedInput
        ariaLabel={ariaLabel}
        value={itemName}
        delay={1000}
        className={classNames(
          'py-1 px-0.5 xs:px-2',
          'bg-transparent',
          disableBorders,
        )}
        onChange={onNameChanged}
        onFocus={() => {
          setIsEditing(true);
          onFocus?.();
        }}
        onBlur={() => {
          onBlur?.();
        }}
        maxLength={maxItemNameLength}
      />
      <ListItemCountsUnits list={list} item={item} isEditing={isEditing} />
    </div>
  );
}
