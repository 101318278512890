import { GrEdit } from 'react-icons/gr';
import { useTranslations } from '@reshima/translations-ui';
import { List, ListSortBy, updateListSortBy } from '@reshima/firebase';
import { Placement, Select, StopClickPropagation } from '@reshima/pure-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { ButtonLink } from '@reshima/navigation-ui';

type Props = {
  list: List;
  placement: Placement;
};

export function SortingDropdown({ list, placement }: Props) {
  const name = 'SortingDropdown';
  const { options, label, labels, ariaLabel, fixedCategoriesAriaLabel } =
    useTranslations()['sorting-dropdown'];

  const onChange = async (sortBy: ListSortBy) => {
    const action = Action.Change;

    const properties = {
      listId: list.id,
      previousSortBy: list.sortBy,
      newSortBy: sortBy,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      await updateListSortBy({ listId: list.id, sortBy });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  };

  return (
    <Select
      ghost
      tight
      placement={placement}
      ariaLabel={ariaLabel}
      value={list.sortBy}
      buttonClassName="py-0.5 px-0.5"
      onChange={(value) => onChange(value as ListSortBy)}
      options={[
        ListSortBy.fixedCategories,
        ListSortBy.lastCreatedItem,
        ListSortBy.alphabetical,
        ListSortBy.manual,
      ].map((value) => ({
        value,
        shortLabel: (
          <div className="flex flex-col text-xs">
            <div className="leading-none">{label}</div>
            <div className="leading-none">{labels[value]}</div>
          </div>
        ),
        longLabel:
          value === ListSortBy.fixedCategories ? (
            <div className="flex items-center gap-2">
              {options[value]}
              <StopClickPropagation className="flex items-center">
                <ButtonLink
                  href={`/list-sorting?id=${list.id}`}
                  ariaLabel={fixedCategoriesAriaLabel}
                >
                  <GrEdit className="text-lg" />
                </ButtonLink>
              </StopClickPropagation>
            </div>
          ) : (
            options[value]
          ),
      }))}
      chevron={false}
    />
  );
}
