import { storeData, getData, deleteAllData } from '@reshima/index-db';

const DB_NAME = 'post-response-cache';
const STORE_NAME = 'responses';

type CachedResponse = {
  response: string;
};

export async function getCachedResponse(
  cacheKey: string,
): Promise<CachedResponse | undefined> {
  try {
    return await getData<CachedResponse>({
      dbName: DB_NAME,
      storeName: STORE_NAME,
      id: cacheKey,
    });
  } catch (error) {
    console.error(`Error getting cached response for key: ${cacheKey}`, error);
    return undefined;
  }
}

export async function storeResponse(
  cacheKey: string,
  data: CachedResponse,
): Promise<void> {
  try {
    await storeData({
      dbName: DB_NAME,
      storeName: STORE_NAME,
      data: {
        id: cacheKey,
        ...data,
      },
    });
  } catch (error) {
    console.error(`Error storing response for key: ${cacheKey}`, error);
  }
}

export async function deleteAllResponses(): Promise<void> {
  try {
    await deleteAllData({
      dbName: DB_NAME,
      storeName: STORE_NAME,
    });
  } catch (error) {
    console.error('Error deleting all cached responses', error);
  }
}
