import { memo, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { LiaUserNinjaSolid, LiaUserSolid } from 'react-icons/lia';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { AppUser } from '@reshima/firebase';
import { SyncStatusIndicator } from './sync-status-indicator';

function UserIcon({
  user,
  loadingUser,
}: {
  user: AppUser | undefined;
  loadingUser: boolean;
}) {
  const { profileImageAltText } = useTranslations()['account-menu'];
  const [userImageError, setUserImageError] = useState(false);

  if (loadingUser) {
    return (
      <div className="p-1.5">
        <AiOutlineLoading className="animate-spin" />
      </div>
    );
  }

  if (!user) {
    return (
      <div className="p-1.5">
        <LiaUserSolid />
      </div>
    );
  }

  if (user.isAnonymous) {
    return (
      <div className="p-1.5">
        <LiaUserNinjaSolid />
      </div>
    );
  }

  if (user.userImage && !userImageError) {
    return (
      <img
        src={user.userImage}
        onError={() => setUserImageError(true)}
        referrerPolicy="no-referrer"
        className="rounded-full w-9 h-9 p-0.5"
        alt={profileImageAltText}
      />
    );
  }

  return (
    <div className="p-1.5">
      <LiaUserSolid />
    </div>
  );
}

const UserIconMemo = memo(UserIcon);

export function AccountMenuImage() {
  const {
    loadingUserAriaLabel,
    gustAriaLabel,
    anonymousAriaLabel,
    userAriaLabel,
  } = useTranslations()['account-menu'];
  const { user, userLoading, isSigningIn } = useClientAuth();

  const loadingUser = userLoading || isSigningIn;

  function getAriaLabel() {
    if (loadingUser) {
      return loadingUserAriaLabel;
    }

    if (user?.isAnonymous) {
      return anonymousAriaLabel;
    }

    if (user?.isSignedIn) {
      return userAriaLabel;
    }

    return gustAriaLabel;
  }

  return (
    <div className="text-2xl relative" aria-label={getAriaLabel()}>
      <UserIconMemo loadingUser={loadingUser} user={user} />
      <SyncStatusIndicator className="absolute bottom-0" />
    </div>
  );
}
