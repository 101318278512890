'use client';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import classNames from 'classnames';
import { ReshimaPage } from '@reshima/shared-ui';
import {
  Item,
  List as IList,
  isItemChecked,
  getListStream,
  getItemsStream,
} from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useNavigate, useSearchParams } from '@reshima/navigation-ui';
import {
  deleteLastVisitedList,
  setLastVisitedList,
} from '@reshima/user-local-persistence';
import { useManagedSyncStatus } from '@reshima/nav-bar-ui';
import { isNew, isExists, uncategorizedNewItemsDelay } from '../shared/utils';
import { AddItemForm, AddItemFormHandler } from '../items/add-item-form';
import { useUpdateItemsLoadingCategories } from '../hooks/useUpdateItemsLoadingCategories';
import { useUpdateLegacyItemsCategories } from '../hooks/useUpdateLegacyItemsCategories';
import { ListContextProvider } from '../lists/list-context';
import { ListActionsDropdown } from '../lists/list-actions-dropdown';
import { ListItemsCompleted } from '../items/list-items-completed';
import { ListItemsDetailsButton } from '../items/list-items-details-button';
import { ListSortingButton } from '../items/list-sorting-button';
import { SortedListItems } from '../items/sorted-list-items';
import { RevertingBanner } from '../reverting/reverting-banner';
import { ListItemsTable } from '../items/list-items-table';

export function List() {
  const name = 'List';
  const {
    list: { previousPageAriaLabel },
    'list-text': { defaultListName },
  } = useTranslations();

  const params = useSearchParams();
  const [list, setList] = useState<IList>();
  const [items, setItems] = useState<Item[]>();
  const [itemName, setItemName] = useState('');
  const addItemFormRef = useRef<AddItemFormHandler>(null);

  // TODO: fix this
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pending, startTransition] = useTransition();

  const { setSyncByStreamSource: setListSyncByStreamSource } =
    useManagedSyncStatus();

  const { setSyncByStreamSource: setItemsSyncByStreamSource } =
    useManagedSyncStatus();

  const navigate = useNavigate();

  const maxItemsCount = useMemo(
    () => Math.max(0, ...(items || []).map(({ count }) => count)),
    [items],
  );

  const listId = params.get('id') || '';

  useUpdateItemsLoadingCategories({ list, items });
  useUpdateLegacyItemsCategories({ list, items });

  const deleteLastVisitedAndRedirect = useCallback(() => {
    deleteLastVisitedList();
    navigate('/lists');
  }, [navigate]);

  const fetchListData = useCallback(
    ({ listId }: { listId: string }) => {
      const action = 'FetchListData';
      const properties = { listId };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      return getListStream({
        listId,
        defaultListName,
        onListUpdate: ({ list, source }) => {
          if (list) {
            setLastVisitedList({ listId: list.id });
            setListSyncByStreamSource(source);
            setList(list);

            trackEvent({
              name,
              action,
              actionModifier: ActionModifier.End,
              properties: {
                ...properties,
                source,
              },
              start,
            });
          } else {
            trackEvent({
              name,
              action,
              actionModifier: ActionModifier.NotFound,
              properties,
              start,
            });

            deleteLastVisitedAndRedirect();
          }
        },
        onError: (error) => {
          trackException({
            name,
            action,
            error,
            properties,
            start,
          });
          deleteLastVisitedAndRedirect();
        },
      });
    },
    [defaultListName, deleteLastVisitedAndRedirect, setListSyncByStreamSource],
  );

  const fetchItemsData = useCallback(
    ({ listId }: { listId: string }) => {
      const action = 'FetchItemsData';
      const properties = { listId };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      return getItemsStream({
        listId,
        onUpdate: ({ items, source }) => {
          setItems(items);
          setItemsSyncByStreamSource(source);

          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            properties: {
              ...properties,
              source,
              itemsLength: items.length,
            },
            start,
          });
        },
        onError: (error) => {
          trackException({
            name,
            action,
            error,
            properties,
            start,
          });
          deleteLastVisitedAndRedirect();
        },
      });
    },
    [deleteLastVisitedAndRedirect, setItemsSyncByStreamSource],
  );

  useEffect(() => {
    if (!listId) {
      trackEvent({
        name,
        action: Action.Load,
        actionModifier: ActionModifier.NotFound,
        properties: { listId },
      });

      navigate('/lists');
    } else {
      const unsubscribeList = fetchListData({ listId });
      const unsubscribeItems = fetchItemsData({ listId });

      return () => {
        unsubscribeList?.();
        unsubscribeItems?.();
      };
    }
  }, [listId, navigate, fetchListData, fetchItemsData]);

  useEffect(() => {
    if (items?.some(isNew)) {
      const timer = setTimeout(
        () => setItems(items.slice()),
        uncategorizedNewItemsDelay + 100,
      );

      return () => clearTimeout(timer);
    }
  }, [items]);

  if (!listId || !list || !items) {
    return null;
  }

  const isFiltering = itemName?.length > 0;

  const filteredItems = isFiltering
    ? items.filter((item) => item.name?.includes(itemName))
    : items;

  const existsItems = filteredItems.filter(isExists);
  const completedItems = filteredItems.filter(isItemChecked);

  return (
    <ListContextProvider
      list={list}
      maxItemsCount={maxItemsCount}
      onItemChecked={() => addItemFormRef.current?.reset()}
    >
      <ReshimaPage
        previousPage={{ href: '/lists', ariaLabel: previousPageAriaLabel }}
        heading={
          <span
            className="text-center text-xl font-semibold"
            data-testid="list-name"
          >
            {list.name}
          </span>
        }
        left={
          <ListActionsDropdown
            list={list}
            items={items}
            existsItems={existsItems}
            onDelete={deleteLastVisitedAndRedirect}
            onLeave={deleteLastVisitedAndRedirect}
          />
        }
      >
        <div className="flex flex-col max-w-sm w-full mx-auto">
          <div className="flex flex-col">
            <AddItemForm
              ref={addItemFormRef}
              list={list}
              className={classNames('py-2 sticky top-10 z-10 bg-base-100')}
              onItemNameChange={(itemName) =>
                startTransition(() => setItemName(itemName))
              }
            />
            <div className="flex w-full">
              <div className="flex flex-1 justify-start">
                <ListItemsDetailsButton itemsLength={existsItems.length} />
              </div>
              {!isFiltering && (
                <>
                  <RevertingBanner className="flex flex-1 justify-center" />
                  <ListSortingButton
                    className="flex flex-1 justify-end"
                    list={list}
                  />
                </>
              )}
            </div>
            {isFiltering ? (
              <ListItemsTable list={list} items={filteredItems} />
            ) : (
              <>
                <SortedListItems list={list} items={existsItems} />
                <ListItemsCompleted
                  className="mt-2"
                  list={list}
                  completedItems={completedItems}
                />
              </>
            )}
          </div>
        </div>
      </ReshimaPage>
    </ListContextProvider>
  );
}
