import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { initAuth } from './firebase-auth';
import { initFirestore } from './firebase-firestore';
import { initCallable } from './firebase-callable';

const name = 'Firebase';

export function initialize({
  config,
  analytics,
}: {
  config: FirebaseOptions;
  analytics: boolean;
}) {
  const action = 'Initialize';

  const properties = {
    analytics,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const app = initializeApp(config);

    if (analytics) {
      getAnalytics(app);
    }

    initFirestore(app);

    initAuth({ app });

    initCallable(app);

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
  }
}
