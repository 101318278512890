import { AppUser, List, Participant, UserData } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { ListParticipant } from './list-participant';

function sortParticipants({
  participants,
  user: {
    firebaseUser: { uid },
  },
}: {
  participants: Participant[];
  user: AppUser;
}): Participant[] {
  return participants.sort((a, b) => {
    if (a.id === uid) {
      return -1;
    }

    if (b.id === uid) {
      return 1;
    }

    return a.createdAt.getTime() - b.createdAt.getTime();
  });
}

export function ListParticipantsTable({
  participants,
  user,
  userData,
}: {
  participants: Participant[];
  user: AppUser;
  userData: UserData;
  list: List;
}) {
  const { noParticipants } = useTranslations()['list-participants'];

  return (
    <div className="flex flex-col gap-2">
      {participants.length <= 0 ? (
        <p>{noParticipants}</p>
      ) : (
        <div className="flex flex-col gap-2">
          {sortParticipants({ participants, user }).map((participant) => (
            <ListParticipant
              key={participant.id}
              user={user}
              userData={userData}
              participant={participant}
            />
          ))}
        </div>
      )}
    </div>
  );
}
