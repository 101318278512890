import { useCallback } from 'react';
import { CategoryId } from '@reshima/category';
import { Item, List, manualUpdateCategorizedItem } from '@reshima/firebase';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { CategoriesPickerButton } from '@reshima/categories-ui';
import { useListContext } from '../lists/list-context';

export function ListItemIconButton({ list, item }: { list: List; item: Item }) {
  const name = 'ListItemIconButton';

  const { addTask } = useListContext();

  const onCategoryIdSelect = useCallback(
    async (categoryId: CategoryId) => {
      const action = Action.Update;

      const oldCategoryId = item.categoryId;

      const properties = {
        listId: list.id,
        itemId: item.id,
        itemName: item.name,
        oldCategoryId,
        newCategoryId: categoryId,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        const redo = () =>
          manualUpdateCategorizedItem({
            list,
            item,
            categoryId,
          });
        const undo = () =>
          manualUpdateCategorizedItem({
            list,
            item,
            categoryId: oldCategoryId as CategoryId, // TODO: Fix this
          });

        await redo();

        addTask({
          redo,
          undo,
        });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
          start,
        });
      }
    },
    [item, list, addTask],
  );

  return (
    <CategoriesPickerButton
      categoryId={item.categoryId}
      categoriesOrder={list.fixedCategoriesOrder}
      onCategoryIdSelect={onCategoryIdSelect}
    />
  );
}
