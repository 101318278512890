import { useState } from 'react';
import { List, deleteList } from '@reshima/firebase';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';

export function DeleteListModal({
  list,
  onDelete,
}: {
  list: List;
  onDelete?: () => void;
}) {
  const name = 'DeleteListModal';
  const { deleteListButton, cancelButton } = useTranslations()['delete-list'];
  const properties = { listId: list.id };

  const [deleting, setDeleting] = useState(false);
  const { closeModal } = useModal();

  function onDeleteClick() {
    const action = Action.Delete;

    setDeleting(true);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      deleteList(list);

      onDelete?.();

      closeModal();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }

    setDeleting(false);
  }

  function onCancelClick() {
    trackEvent({
      name,
      action: Action.Cancel,
      actionModifier: ActionModifier.End,
      properties,
    });

    closeModal();
  }

  return (
    <div className="flex justify-center gap-2">
      <Button onClick={onDeleteClick} disabled={deleting} danger>
        {deleteListButton}
      </Button>
      <Button onClick={onCancelClick}>{cancelButton}</Button>
    </div>
  );
}
