import classNames from 'classnames';
import { forwardRef } from 'react';

type Props = {
  isOpen: boolean;
  ariaLabel: string;
  onClick: () => void;
};

export const Hamburger = forwardRef<HTMLButtonElement, Props>(
  function Hamburger({ isOpen, ariaLabel, onClick }: Props, ref) {
    const line =
      'absolute h-[3px] bg-base-content rounded-md transition-all duration-200 ease-in-out';

    const middle = classNames(line, 'w-full top-1/2 -translate-y-1/2');
    const edge = classNames(line, isOpen ? 'w-0 left-1/2' : 'w-full left-0');

    return (
      <button
        ref={ref}
        className="py-2.5 px-1.5"
        aria-label={ariaLabel}
        onClick={onClick}
      >
        <div className="w-6 h-4 relative cursor-pointer">
          <div
            className={classNames(
              edge,
              isOpen ? 'top-1/2 -translate-y-1/2' : 'top-0 translate-y-0',
            )}
          />
          <div className={classNames(middle, { 'rotate-45': isOpen })} />
          <div className={classNames(middle, { '-rotate-45': isOpen })} />
          <div
            className={classNames(
              edge,
              isOpen ? 'bottom-1/2 translate-y-1/2' : 'bottom-0 translate-y-0',
            )}
          />
        </div>
      </button>
    );
  },
);
