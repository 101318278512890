import { ImExit } from 'react-icons/im';
import { Button } from '@reshima/pure-ui';
import { List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { LeaveListModal } from './leave-list-modal';

export function LeaveListButton({
  list,
  onLeave,
}: {
  list: List;
  onLeave?: () => void;
}) {
  const name = 'LeaveListButton';
  const { button, heading, description } = useTranslations()['leave-list'];

  const { showModal } = useModal();

  const properties = { listId: list.id };

  function onLeaveButtonClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      description,
      content: <LeaveListModal list={list} onLeave={onLeave} />,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button
      className="flex items-center gap-2"
      onClick={onLeaveButtonClick}
      ghost
    >
      <ImExit className="text-lg" />
      {button}
    </Button>
  );
}
