'use client';
import { useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { useNavigate, useSearchParams } from '@reshima/navigation-ui';
import { joinList } from '@reshima/firebase';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { SignIn, useClientAuth } from '@reshima/client-auth-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';

export function JoiningList() {
  const name = 'JoiningList';
  const {
    signInDescription,
    joinListButton,
    joiningListButton,
    description,
    error,
  } = useTranslations()['join'];
  const [joining, setJoining] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const params = useSearchParams();
  const { user, userLoading } = useClientAuth();
  const shareLink = params.get('key') || params.get('id'); // backward compatibility

  useEffect(() => {
    if (!shareLink) {
      trackEvent({
        name,
        action: Action.Load,
        actionModifier: ActionModifier.NotFound,
        properties: { shareLink },
      });
      navigate(`/`);
    }
  }, [shareLink, navigate]);

  async function onClick({ shareLink }: { shareLink: string }) {
    const action = Action.Join;

    const properties = {
      shareLink,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    setIsError(false);
    setJoining(true);

    try {
      const { id } = await joinList({
        shareLink,
      });

      const url = `/list?id=${id}`;

      navigate(url);

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
      setJoining(false);
      setIsError(true);
    }
  }

  function SignInBeforeJoining() {
    return (
      <div className="flex flex-col items-center gap-2 text-center">
        <p>{signInDescription}</p>
        <SignIn />
      </div>
    );
  }

  if (!shareLink) {
    return null;
  }

  if (userLoading) {
    return <AiOutlineLoading className="text-3xl animate-spin mx-auto" />;
  }

  if (!user?.isSignedIn) {
    return <SignInBeforeJoining />;
  }

  return (
    <div className="flex flex-col items-center gap-2 text-center">
      <p>{description}</p>
      <Button disabled={joining} onClick={() => onClick({ shareLink })}>
        <div className="flex items-center gap-2">
          {joining && <AiOutlineLoading className="animate-spin" />}
          {joining ? joiningListButton : joinListButton}
        </div>
      </Button>
      {isError && <p className="text-red-500">{error}</p>}
    </div>
  );
}
