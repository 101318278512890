import { finalLetterToRegular } from './final-letters';
import { removeNiqqud } from './niqqud';

export function extractInflections({
  word,
  inflectionsText,
}: {
  word: string;
  inflectionsText?: string;
}): string[] {
  if (!inflectionsText) {
    return [];
  }

  const nonSuffixTitle = finalLetterToRegular(word);

  const exceptedTitle = nonSuffixTitle.replace(/(ה|ת|י)$/, '');

  const nonNiqqudInflections = removeNiqqud(inflectionsText);

  const inflections = nonNiqqudInflections.match(
    new RegExp(`${exceptedTitle}[א-ת]+`, 'g'),
  );

  return inflections || [];
}
