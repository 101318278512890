import { HiOutlineChevronDown } from 'react-icons/hi';
import {
  CategoryId,
  itemCategories,
  getCategoryIcon,
  itemCategoriesIds,
} from '@reshima/category';
import { useTranslations } from '@reshima/translations-ui';
import { Checkbox, Dropdown, Placement } from '@reshima/pure-ui';

export function CategoriesMultiSelect({
  categoriesIds,
  placement,
  onCategoriesIdsChange,
}: {
  categoriesIds: CategoryId[];
  placement: Placement;
  onCategoriesIdsChange: (categoriesIds: CategoryId[]) => void;
}) {
  const dictionary = useTranslations();

  const {
    emptyPlaceholder,
    selected,
    selectAll,
    ariaLabel,
    selectCategoryAriaLabel,
  } = dictionary['categories-multiselect'];

  const isAllSelected = itemCategoriesIds.every((id) =>
    categoriesIds.includes(id),
  );

  function onSelectAllClick() {
    onCategoriesIdsChange(isAllSelected ? [] : itemCategoriesIds);
  }

  return (
    <Dropdown
      ghost
      ariaLabel={ariaLabel}
      placement={placement}
      container={
        <div className="flex items-center gap-2 cursor-pointer ps-1 pe-2">
          <div>
            <HiOutlineChevronDown className="text-lg" />
          </div>
          {categoriesIds.length ? (
            <div className="flex items-center gap-2">
              {categoriesIds.length === 1 ? (
                <>
                  <span className="text-xl">
                    {getCategoryIcon(categoriesIds[0])}
                  </span>
                  <span>{dictionary.categories[categoriesIds[0]]}</span>
                </>
              ) : (
                <span>{`${categoriesIds.length} ${selected}`}</span>
              )}
            </div>
          ) : (
            <div>{emptyPlaceholder}</div>
          )}
        </div>
      }
    >
      <ul>
        <li
          className="flex items-center gap-2 cursor-pointer py-0.5 px-2"
          onClick={onSelectAllClick}
        >
          <Checkbox
            checked={isAllSelected}
            ariaLabel={selectAll}
            onChange={onSelectAllClick}
          />
          <span>{selectAll}</span>
        </li>
        {itemCategories.map(({ id }) => {
          const checked = categoriesIds.includes(id);

          function onCategoryClick() {
            onCategoriesIdsChange(
              checked
                ? categoriesIds.filter((categoryId) => categoryId !== id)
                : [...categoriesIds, id],
            );
          }

          const category = dictionary.categories[id];

          return (
            <li
              key={id}
              className="flex items-center gap-2 cursor-pointer py-0.5 px-2"
              onClick={onCategoryClick}
            >
              <Checkbox
                checked={checked}
                ariaLabel={`${selectCategoryAriaLabel} ${category}`}
                onChange={onCategoryClick}
              />
              <div className="flex items-center gap-2 cursor-pointer">
                <span className="text-xl">{getCategoryIcon(id)}</span>
                <span>{category}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </Dropdown>
  );
}
