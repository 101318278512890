import { CategoryId, getCategoryIcon } from '@reshima/category';
import { useTranslations } from '@reshima/translations-ui';
import classNames from 'classnames';

export function CategoryLabelIcon({
  categoryId,
  className,
}: {
  categoryId: CategoryId;
  className?: string;
}) {
  const { categories } = useTranslations();

  return (
    <div className={classNames('flex items-center gap-2', className)}>
      <span className="text-xl">{getCategoryIcon(categoryId)}</span>
      <span>{categories[categoryId]}</span>
    </div>
  );
}
