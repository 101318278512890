'use client';
import { useCallback, useEffect, useState } from 'react';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { AppUser, getCustomToken } from '@reshima/firebase';
import { isAndroidWebInterfaceAvailable } from '@reshima/shared-ui';

type AuthenticationResponseStatus = 'success' | 'error';

type AndroidAuthenticationResponseParams = {
  status: AuthenticationResponseStatus;
  error?: string;
};

declare global {
  interface Window {
    AndroidGetCurrentUser?: (uid: string) => void;
    AndroidAuthenticationResponse?: (
      params: AndroidAuthenticationResponseParams,
    ) => void;
  }
}

const unauthenticated = 'unauthenticated';

export function useWebViewToAndroidAuthSync({
  user,
  userLoading,
}: {
  user?: AppUser;
  userLoading: boolean;
}) {
  const name = 'UseWebViewToAndroidAuthSync';

  const [androidUserId, setAndroidUserId] = useState<string>();

  const postCustomTokenToApp = useCallback(async () => {
    const action = Action.SignInRequest;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    try {
      const customToken = await getCustomToken();

      window.AndroidWebInterface.signInWithCustomToken(customToken);

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
        properties: {
          customTokenLength: customToken.length,
        },
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
      });
    }
  }, []);

  const postSignOutToApp = useCallback(() => {
    const start = trackEvent({
      name,
      action: Action.SignOut,
      actionModifier: ActionModifier.Start,
    });

    try {
      window.AndroidWebInterface.signOut();

      trackEvent({
        name,
        action: Action.SignOut,
        actionModifier: ActionModifier.End,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action: Action.SignOut,
        error,
        start,
      });
    }
  }, []);

  const androidGetCurrentUser = useCallback(async (userId: string) => {
    setAndroidUserId(userId);

    trackEvent({
      name,
      action: Action.Get,
      actionModifier: ActionModifier.End,
      properties: {
        userId,
      },
    });
  }, []);

  const androidAuthenticationResponse = useCallback(
    ({ status, error }: AndroidAuthenticationResponseParams) => {
      if (status === 'success') {
        trackEvent({
          name,
          action: Action.Sync,
          actionModifier: ActionModifier.End,
        });
      } else {
        trackException({
          name,
          action: Action.Sync,
          error: new Error(error || 'Unknown error'),
        });
      }
    },
    [],
  );

  useEffect(() => {
    if (!isAndroidWebInterfaceAvailable()) return;
    if (userLoading) return;
    if (!androidUserId) return;

    const webViewUserId = user?.firebaseUser.uid || unauthenticated;
    const isWebViewAuthenticated = webViewUserId !== unauthenticated;

    const properties = {
      webViewUserId,
      androidUserId,
      isWebViewAuthenticated,
    };

    if (webViewUserId === androidUserId) {
      trackEvent({
        name,
        action: Action.Sync,
        actionModifier: ActionModifier.Skip,
        properties,
      });
      return;
    }

    trackEvent({
      name,
      action: Action.Sync,
      actionModifier: ActionModifier.Start,
      properties,
    });

    if (isWebViewAuthenticated) {
      postCustomTokenToApp();
    } else {
      postSignOutToApp();
    }
  }, [
    postCustomTokenToApp,
    postSignOutToApp,
    user,
    userLoading,
    androidUserId,
  ]);

  useEffect(() => {
    if (isAndroidWebInterfaceAvailable() && window.AndroidWebInterface.getCurrentUserReady) {
      window.AndroidGetCurrentUser = androidGetCurrentUser;
      window.AndroidAuthenticationResponse = androidAuthenticationResponse;
      window.AndroidWebInterface.getCurrentUserReady();
    }
    return () => {
      window.AndroidGetCurrentUser = undefined;
      window.AndroidAuthenticationResponse = undefined;
    };
  }, [androidGetCurrentUser, androidAuthenticationResponse]);
}
