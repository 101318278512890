import {
  FirestoreError,
  Timestamp,
  Unsubscribe,
  deleteField,
  doc,
  getDocFromCache,
  increment,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { getCollection } from '../collections';
import {
  AddingItemAutoComplete,
  FirebaseUserData,
  FirebaseUserMessages,
  Settings,
  StreamSource,
  UpdateAbleUserData,
  UserData,
  UserMessageId,
  UserMessages,
} from '../models';
import { getStreamSource } from './metadata';

const getUsersCollection = () => getCollection('users');

const name = 'FirebaseUsers';

function parseMessages(messages?: FirebaseUserMessages): UserMessages {
  const userMessages: UserMessages = Object.fromEntries(
    Object.entries(messages || {}).map(([id, message]) => {
      const hiddenAt = message?.hiddenAt?.toDate();
      return [id, { ...message, hiddenAt }];
    }),
  );

  return userMessages;
}

function parse({
  id,
  userData,
}: {
  id: string;
  userData?: FirebaseUserData;
}): UserData {
  const updatedAt = userData?.updatedAt?.toDate() || new Date();

  const {
    parseItemCount = true,
    parseItemUnit = true,
    parseItemCategory = true,
  } = userData?.settings?.addingItem?.autoComplete || {};

  const settings: Settings = {
    addingItem: {
      autoComplete: {
        parseItemCount,
        parseItemUnit,
        parseItemCategory,
      },
    },
  };

  const messages = parseMessages(userData?.messages);

  return {
    ...userData,
    id,
    name: userData?.name || '',
    contacts: userData?.contacts || {},
    updatedAt,
    settings,
    listsOrder: userData?.listsOrder || [],
    messages,
  };
}

async function updateUserData(userData: UpdateAbleUserData): Promise<void> {
  await setDoc(
    doc(getUsersCollection(), userData.id),
    {
      ...userData,
      updatedAt: serverTimestamp(),
    },
    {
      merge: true,
    },
  );
}

export async function getUserDataFromCache({
  userId,
}: {
  userId: string;
}): Promise<UserData | undefined> {
  const action = 'GetUserDataFromCache';

  const properties = {
    userId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const docSnapshot = await getDocFromCache(
      doc(getUsersCollection(), userId),
    );

    if (!docSnapshot.exists()) {
      return undefined;
    }

    const userData = parse({
      id: docSnapshot.id,
      userData: docSnapshot.data(),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });

    return userData;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export function getUserDataStream({
  userId,
  onUpdate,
  onError,
}: {
  userId: string;
  onUpdate: (params: { user?: UserData; source: StreamSource }) => void;
  onError: (error: FirestoreError) => void;
}): Unsubscribe {
  return onSnapshot(
    doc(getUsersCollection(), userId),
    { includeMetadataChanges: true },
    (snapshot) => {
      const user: UserData = parse({
        id: snapshot.id,
        userData: snapshot.data(),
      });

      const source = getStreamSource(snapshot);

      onUpdate({ user, source });
    },
    async (error) => {
      return onError(error);
    },
  );
}

export async function updateUserDataName({
  userId,
  newName,
}: {
  userId: string;
  newName: string;
}): Promise<void> {
  const action = 'UpdateUserDataName';

  const properties = {
    userId,
    newNameLength: newName?.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateUserData({
      id: userId,
      name: newName,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateUserMessageHiddenAt({
  userId,
  messageId,
}: {
  userId: string;
  messageId: UserMessageId;
}): Promise<void> {
  const action = 'UpdateUserMessageHiddenAt';

  const properties = {
    userId,
    messageId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const userRef = doc(getUsersCollection(), userId);

    await setDoc(
      userRef,
      {
        messages: {
          [messageId]: {
            hiddenAt: Timestamp.now(),
          },
        },
      },
      { merge: true },
    );

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateUserMessageShownCount({
  userId,
  messageId,
}: {
  userId: string;
  messageId: UserMessageId;
}): Promise<void> {
  const action = 'UpdateUserMessageShownCount';

  const properties = {
    userId,
    messageId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const userRef = doc(getUsersCollection(), userId);

    await setDoc(
      userRef,
      {
        messages: {
          [messageId]: {
            shownCount: increment(1),
          },
        },
      },
      { merge: true },
    );

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateContactName({
  userId,
  contactId,
  contactName,
}: {
  userId: string;
  contactId: string;
  contactName: string;
}): Promise<void> {
  const action = 'UpdateContactName';

  const properties = {
    userId,
    contactId,
    contactName,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const userRef = doc(getUsersCollection(), userId);

    await setDoc(
      userRef,
      {
        contacts: {
          [contactId]: {
            name: contactName ? contactName : deleteField(),
          },
        },
      },
      { merge: true },
    );

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateUserDataSettingsAddingItemAutoComplete({
  userId,
  setting,
  value,
}: {
  userId: string;
  setting: keyof AddingItemAutoComplete;
  value: boolean;
}): Promise<void> {
  const action = 'UpdateUserDataSettingsAddingItemAutoComplete';

  const properties = {
    userId,
    setting,
    value,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const userRef = doc(getUsersCollection(), userId);

    await setDoc(
      userRef,
      {
        settings: {
          addingItem: {
            autoComplete: { [setting]: value },
          },
        },
      },
      { merge: true },
    );

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateListsOrder({
  userId,
  listsOrder,
}: {
  userId: string;
  listsOrder: string[];
}): Promise<void> {
  const action = 'UpdateListsOrder';

  const properties = {
    userId,
    listsOrderLength: listsOrder?.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateUserData({
      id: userId,
      listsOrder,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}
