import { measurementWords } from './measurement-words';
import { packagingWords } from './packaging-words';
import { sizingWords } from './sizing-words';
import { numbersWords } from './numbers-words';
import { adjectiveWords } from './adjective-words';
import { colorsWords } from './colors-words';
import { arithmeticWords } from './arithmetic-words';

export const Words = {
  measurementWords,
  packagingWords,
  sizingWords,
  numbersWords,
  adjectiveWords,
  colorsWords,
  arithmeticWords,
};
