import { isErrorMessage } from '@reshima/messages';
import {
  SignInResponseErrorMessage,
  SignInResponseTokenMessage,
  SignInWithCustomTokenResponseMessage,
  SignOutResponseMessage,
} from './models';

export function isSignInResponseErrorMessage(
  message: unknown,
): message is SignInResponseErrorMessage {
  return isErrorMessage(message);
}

export function isSignInResponseTokenMessage(
  message: unknown,
): message is SignInResponseTokenMessage {
  return (message as SignInResponseTokenMessage).idToken !== undefined;
}

export function isSignInWithCustomTokenResponseMessage(
  message: unknown,
): message is SignInWithCustomTokenResponseMessage {
  return (
    (message as SignInWithCustomTokenResponseMessage).action ===
    'signInWithCustomToken'
  );
}

export function isSignOutResponseMessage(
  message: unknown,
): message is SignOutResponseMessage {
  return (message as SignOutResponseMessage).action === 'signOut';
}
