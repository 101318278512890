import classNames from 'classnames';
import { AiOutlineDelete } from 'react-icons/ai';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { Item, List, deleteItem, restoreItem } from '@reshima/firebase';
import { ItemActivityType } from '@reshima/list-activity-shared';
import { useListContext } from '../lists/list-context';

type Props = {
  list: List;
  item: Item;
  className?: string;
};

export function ListItemDeleteButton({ list, item, className }: Props) {
  const name = 'ListItemDeleteButton';

  const listId = list.id;

  const properties = {
    listId,
    itemId: item.id,
  };

  const { addTask } = useListContext();
  const { ariaLabel } = useTranslations()['delete-list-item'];

  async function onDeleteClick(item: Item) {
    const action = Action.Delete;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const redo = () => deleteItem({ listId, item });
      const undo = () => restoreItem({ listId, item });

      await redo();

      addTask({
        undo,
        redo,
        activity: {
          items: [item],
          type: ItemActivityType.deleted,
        },
      });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  return (
    <Button
      className={classNames('text-xl', className)}
      onClick={() => onDeleteClick(item)}
      ariaLabel={`${ariaLabel} ${item.name}`}
      ghost
      circle
    >
      <div className="p-1">
        <AiOutlineDelete />
      </div>
    </Button>
  );
}
