import { useState } from 'react';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { CategoriesPicker } from '@reshima/categories-ui';
import { CategoryId } from '@reshima/category';
import { useModal } from '@reshima/modals-ui';

export function CategoriesPickerModal({
  categoryId,
  categoriesOrder,
  onCategoryIdSelect,
}: {
  categoryId: CategoryId;
  categoriesOrder?: CategoryId[];
  onCategoryIdSelect: (categoryId: CategoryId) => void;
}) {
  const { updateButton, cancelButton } =
    useTranslations()['update-list-item-category'];

  const [currentCategoryId, setCurrentCategoryId] = useState(categoryId);
  const { closeModal } = useModal();

  return (
    <div className="flex flex-col gap-4 min-h-0">
      <CategoriesPicker
        className="min-h-0"
        categoryId={currentCategoryId}
        categoriesOrder={categoriesOrder}
        onCategoryIdChange={setCurrentCategoryId}
      />
      <div className="flex justify-center gap-2">
        {currentCategoryId ? (
          <Button
            onClick={() => {
              closeModal();
              onCategoryIdSelect(currentCategoryId);
            }}
            disabled={categoryId === currentCategoryId}
          >
            {updateButton}
          </Button>
        ) : (
          <Button disabled>{updateButton}</Button>
        )}
        <Button onClick={closeModal} secondary>
          {cancelButton}
        </Button>
      </div>
    </div>
  );
}
