import {
  Item,
  List,
  ListSortByDirection,
  sortItemsByNameAsc,
  sortItemsByNameDesc,
} from '@reshima/firebase';
import { ListItemsTable } from './list-items-table';

export function ListItemsTableName({
  list,
  items,
}: {
  list: List;
  items: Item[];
}) {
  const sortedItems = items.sort(
    list.sortByDirection === ListSortByDirection.asc
      ? sortItemsByNameAsc
      : sortItemsByNameDesc,
  );

  return <ListItemsTable list={list} items={sortedItems} />;
}
