/* eslint-disable @typescript-eslint/no-empty-function */
'use client';
import {
  PropsWithChildren,
  FC,
  useState,
  createContext,
  useContext,
} from 'react';
import { Modal } from './modal';
import { ModalContent } from './models';

interface ModalContext {
  modalContent?: ModalContent;
  isModalOpen?: boolean;
  showModal: (modalContent: ModalContent) => void;
  closeModal: () => void;
}

export const context = createContext<ModalContext>({
  showModal: () => {},
  closeModal: () => {},
});

export function useModal(): ModalContext {
  return useContext(context);
}

export function ModalProvider({ children }: PropsWithChildren): ReturnType<FC> {
  const [modalContent, setModalContent] = useState<ModalContent>();

  return (
    <context.Provider
      value={{
        modalContent,
        isModalOpen: !!modalContent,
        showModal: setModalContent,
        closeModal: () => setModalContent(undefined),
      }}
    >
      <Modal />
      {children}
    </context.Provider>
  );
}
