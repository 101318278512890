import { HiUsers } from 'react-icons/hi2';
import { ButtonLink } from '@reshima/navigation-ui';
import { useTranslations } from '@reshima/translations-ui';

export function ListParticipantsButton({ listId }: { listId: string }) {
  const { button, buttonAriaLabel } = useTranslations()['list-participants'];

  return (
    <ButtonLink
      href={`/list-members?id=${listId}`}
      ariaLabel={buttonAriaLabel}
      ghost
    >
      <div className="flex items-center gap-2">
        <HiUsers className="text-lg" />
        {button}
      </div>
    </ButtonLink>
  );
}
