import { useRef } from 'react';
import classNames from 'classnames';
import { Units } from '@reshima/shared';
import { Item, List, updateItemCount, updateItemUnit } from '@reshima/firebase';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useListContext } from '../lists/list-context';
import { UnitSelect } from './unit-select';

export function ListItemCountsUnits({
  list,
  item,
  isEditing,
}: {
  list: List;
  item: Item;
  isEditing: boolean;
}) {
  const name = 'ListItemText';
  const counterRef = useRef<HTMLInputElement>(null);
  const { maxItemsCount, addTask } = useListContext();

  async function onItemCountChange({ count }: { count: number }) {
    const action = Action.Check;

    const previousCount = item.count;

    const properties = {
      listId: list.id,
      itemId: item.id,
      count,
      previousCount,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const redo = () => updateItemCount({ list, itemId: item.id, count });
      const undo = () =>
        updateItemCount({
          list,
          itemId: item.id,
          count: previousCount,
        });

      await redo();

      addTask({ redo, undo });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  async function onItemUnitChange({ unit }: { unit: Units }) {
    const action = Action.Check;

    const previousUnit = item.unit;

    const properties = {
      listId: list.id,
      itemId: item.id,
      unit,
      previousUnit,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const redo = () => updateItemUnit({ list, itemId: item.id, unit });
      const undo = () =>
        updateItemUnit({
          list,
          itemId: item.id,
          unit: previousUnit,
        });

      await redo();

      addTask({ redo, undo });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  const isCounterFocus = counterRef.current === document.activeElement;

  const showCount =
    isEditing || item.count > 1 || isCounterFocus || item.unit !== Units.pcs;

  const countValue =
    item.count >= 1 ? item.count : isEditing && !isCounterFocus ? 1 : '';

  return (
    <div className="flex items-stretch gap-0.5">
      <input
        ref={counterRef}
        type="number"
        inputMode="numeric"
        className={classNames(
          'box-content',
          'py-0.5 px-1',
          'text-sm text-right',
          'bg-transparent',
          'rounded-md border-transparent focus-within:ring-2 focus-within:ring-blue-500',
          '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
          { hidden: !showCount },
        )}
        style={{ width: `${maxItemsCount.toString().length}ch` }}
        value={countValue}
        onFocus={(e) => e.target.select()}
        onChange={(e) =>
          onItemCountChange({
            count: +e.target.value,
          })
        }
      />
      <UnitSelect
        unit={item.unit}
        onChange={(unit) => onItemUnitChange({ unit })}
        buttonClassName={classNames(
          'text-sm py-0.5 px-0.5 border border-transparent',
          {
            hidden: !showCount,
          },
        )}
        optionClassName="text-sm"
        chevron={false}
        tight
      />
    </div>
  );
}
