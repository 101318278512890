import {
  Item,
  List,
  ListItemsCompletedSortBy,
  ListSortByDirection,
  sortItemsByCheckedUpdatedAtAsc,
  sortItemsByCheckedUpdatedAtDesc,
  sortItemsByNameAsc,
  sortItemsByNameDesc,
} from '@reshima/firebase';
import { ListItemsTable } from './list-items-table';

const sortingFunctions: Record<
  ListItemsCompletedSortBy,
  Record<ListSortByDirection, (a: Item, b: Item) => number>
> = {
  [ListItemsCompletedSortBy.alphabetical]: {
    [ListSortByDirection.asc]: sortItemsByNameAsc,
    [ListSortByDirection.desc]: sortItemsByNameDesc,
  },
  [ListItemsCompletedSortBy.lastCheckedItem]: {
    [ListSortByDirection.asc]: sortItemsByCheckedUpdatedAtAsc,
    [ListSortByDirection.desc]: sortItemsByCheckedUpdatedAtDesc,
  },
};

export function ListItemsTableChecked({
  list,
  items,
}: {
  list: List;
  items: Item[];
}) {
  const sortedItems = items.sort(
    sortingFunctions[list.completedItemsSortBy][
      list.completedItemsSortByDirection
    ],
  );

  return <ListItemsTable list={list} items={sortedItems} />;
}
