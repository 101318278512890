import { categoriesMap } from '@reshima/category';
import { isItemChecked, Item } from '@reshima/firebase';

export const uncategorizedNewItemsDelay = 1000;

function getItemAge({
  clientCreatedAt,
}: {
  clientCreatedAt: Item['clientCreatedAt'];
}): number {
  return Date.now() - clientCreatedAt.getTime();
}

export function isNew({ categoryId, clientCreatedAt }: Item): boolean {
  return (
    categoryId === categoriesMap.Loading.id &&
    getItemAge({ clientCreatedAt }) < uncategorizedNewItemsDelay
  );
}

export function isExists({
  categoryId,
  clientCreatedAt,
  checked,
}: Item): boolean {
  return (
    !isItemChecked({ checked }) &&
    (categoryId !== categoriesMap.Loading.id ||
      getItemAge({ clientCreatedAt }) > uncategorizedNewItemsDelay)
  );
}
