import { useState } from 'react';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { AppUser } from '@reshima/firebase';
import { deleteAllActivityItems } from '@reshima/list-activity-shared';

type Props = {
  listId: string;
  onDelete: () => void;
};

export function DeleteListActivityModal({ listId, onDelete }: Props) {
  const name = 'DeleteListActivityModal';
  const {
    deleteListActivityButton,
    cancelButton,
    description,
    confirmDelete,
    error,
  } = useTranslations()['delete-list-activity'];
  const properties = { listId };

  const [deleting, setDeleting] = useState(false);
  const [isError, setIsError] = useState(false);
  const { closeModal } = useModal();
  const { user } = useClientAuth();

  async function onDeleteClick({ user }: { user: AppUser }) {
    const action = Action.Delete;

    setDeleting(true);
    setIsError(false);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const token = await user.firebaseUser.getIdToken();

      if (!token) {
        throw new Error('Token not found');
      }

      await deleteAllActivityItems({
        listId,
        token,
      });

      closeModal();

      onDelete();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      setIsError(true);
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }

    setDeleting(false);
  }

  function onCancelClick() {
    trackEvent({
      name,
      action: Action.Cancel,
      actionModifier: ActionModifier.End,
      properties,
    });

    closeModal();
  }

  return (
    <div className="max-w-sm flex flex-col gap-4">
      <p className="italic">{description}</p>
      <p className="text-center">{confirmDelete}</p>
      {isError && <p className="items-center text-red-500">{error}</p>}
      <div className="flex justify-center gap-2">
        {user ? (
          <Button
            onClick={() => onDeleteClick({ user })}
            disabled={deleting}
            danger
          >
            {deleteListActivityButton}
          </Button>
        ) : (
          <Button disabled danger>
            {deleteListActivityButton}
          </Button>
        )}
        <Button onClick={onCancelClick}>{cancelButton}</Button>
      </div>
    </div>
  );
}
