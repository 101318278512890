import { ReactElement } from 'react';
import classNames from 'classnames';
import { LiaUserSolid } from 'react-icons/lia';
import {
  AppUser,
  Participant,
  updateContactName,
  UserData,
} from '@reshima/firebase';
import { DebouncedInput } from '@reshima/pure-ui';
import { formatDate } from '@reshima/shared';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { Dictionary } from '@reshima/translations';
import { useTranslations } from '@reshima/translations-ui';

function getParticipantPlaceholderName({
  dictionary,
  participant,
}: {
  dictionary: Dictionary;
  participant: Participant;
}): string {
  return (
    participant.name ||
    `${dictionary['list-participants'].noParticipantName} #${participant.index + 1}`
  );
}

export function ListParticipant({
  user,
  userData,
  participant,
}: {
  user: AppUser;
  userData: UserData;
  participant: Participant;
}): ReactElement {
  const name = 'ListParticipant';

  const dictionary = useTranslations();
  const { ariaLabel, currentParticipantName, joinedAt, identifier } =
    dictionary['list-participant'];

  const shortId = participant.id.slice(0, 5);

  async function onParticipantNameChange({
    contactName,
  }: {
    contactName: string;
  }) {
    const action = Action.Update;

    const properties = {
      participantId: participant.id,
      nameLength: name.length,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      await updateContactName({
        userId: user.firebaseUser.uid,
        contactId: participant.id,
        contactName,
      });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
        properties,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
        properties,
      });
    }
  }

  return (
    <div
      className={classNames(
        'px-2 xs:px-3 py-2 xs:py-3',
        'flex flex-col xs:flex-row items-start xs:items-center xs:justify-between gap-2',
        'border border-base-300 shadow-md rounded-md',
      )}
      data-testid={`list-participant-${participant.id}`}
    >
      <div className="flex-1 flex items-center gap-2">
        <div className="p-1 rounded-full border border-gray-400">
          <LiaUserSolid className="text-2xl" />
        </div>
        {user.firebaseUser.uid === participant.id ? (
          <div className="px-1 font-semibold" aria-label={ariaLabel}>
            {currentParticipantName}
          </div>
        ) : (
          <DebouncedInput
            delay={300}
            ariaLabel={ariaLabel}
            onChange={(contactName) => onParticipantNameChange({ contactName })}
            value={userData.contacts[participant.id]?.name}
            placeholder={getParticipantPlaceholderName({
              dictionary,
              participant,
            })}
            className="px-1 py-0.5 bg-base-100 border-0 rounded-md"
          />
        )}
      </div>
      <div className="flex flex-col text-xs text-secondary-base-content">
        <div className="flex">
          <div>{joinedAt}</div>
          <div className="italic">{formatDate(participant.createdAt)}</div>
        </div>
        <div className="flex gap-1">
          <div>{identifier}</div>
          <div className="italic">{shortId}</div>
        </div>
      </div>
    </div>
  );
}
