import { useState } from 'react';
import { List } from '@reshima/firebase';
import { Button, EditableText } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { isAndroidWebInterfaceAvailable } from '@reshima/shared-ui';
import {
  CreateShortcutRequestMessage,
  isAndroidMessageAvailable,
} from '@reshima/android-ui';

export function ListShortcutModal({ list }: { list: List }) {
  const name = 'ListShortcutModal';
  const {
    shortcutButton,
    cancelButton,
    shortcutAriaLabel,
    shortcutPlaceholder,
  } = useTranslations()['list-shortcut'];
  const [shortcutName, setShortcutName] = useState(list.name);
  const { closeModal } = useModal();

  async function onShortcutClick() {
    const action = Action.Shortcut;

    const url = `${window.location.origin}/list?id=${list.id}`;

    const properties = {
      listId: list.id,
      shortcutName,
      url,
    };

    if (!shortcutName) {
      return;
    }

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      if (isAndroidWebInterfaceAvailable()) {
        window.AndroidWebInterface?.createShortcut(url, shortcutName);
      } else if (isAndroidMessageAvailable()) {
        const message: CreateShortcutRequestMessage = {
          action: 'createShortcut',
          url,
          name: shortcutName,
        };
        window.androidMessagePort.postMessage(JSON.stringify(message));
      } else {
        throw new Error('No Android interface available');
      }

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });

      closeModal();
    } catch (error) {
      trackException({
        name,
        action,
        properties,
        start,
        error,
      });
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <EditableText
        className="px-1 py-0.5 rounded-md bg-base-100"
        ariaLabel={shortcutAriaLabel}
        placeholder={shortcutPlaceholder}
        value={shortcutName}
        onChange={setShortcutName}
        selectOnFocus
      />
      <div className="flex justify-center gap-2">
        <Button onClick={onShortcutClick} disabled={!shortcutName}>
          {shortcutButton}
        </Button>
        <Button onClick={closeModal} secondary>
          {cancelButton}
        </Button>
      </div>
    </div>
  );
}
