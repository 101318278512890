import { useState, useRef, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ThemeToggler } from '@reshima/theme';
import { MotionDiv } from '@reshima/shared-ui';
import { useTranslations } from '@reshima/translations-ui';
import { ButtonLink } from '@reshima/navigation-ui';
import { Dictionary } from '@reshima/translations';
import { UserData } from '@reshima/firebase';
import { useClientAuth } from '@reshima/client-auth-ui';
import { Hamburger } from './hamburger';

type MenuItem = {
  label: string;
  path: string;
};

function isMenuItem(item: MenuItem | { label: string }): item is MenuItem {
  return (item as MenuItem).path !== undefined;
}

function getAdminMenu({
  dictionary,
  userData,
}: {
  dictionary: Dictionary;
  userData?: UserData;
}): MenuItem[] {
  const {
    inflections,
    menuCatalog,
    itemsCatalog,
    usersSearchesItems,
    itemsCategories,
  } = dictionary['account-menu'];

  if (userData?.isAdmin) {
    return [
      {
        label: inflections,
        path: 'catalog/inflections',
      },
      {
        label: menuCatalog,
        path: 'catalog/menu',
      },
      {
        label: itemsCatalog,
        path: 'catalog/items',
      },
      {
        label: usersSearchesItems,
        path: 'users-searches-items',
      },
      {
        label: itemsCategories,
        path: 'items-categories',
      },
    ];
  }

  return [];
}

export const Menu = () => {
  const dictionary = useTranslations();
  const [isOpen, setOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { menuAriaLabel, version, contact, privacyPolicy } = dictionary['menu'];

  const { userData } = useClientAuth();

  const routes: MenuItem[] = [
    {
      label: contact,
      path: '/contact',
    },
    ...getAdminMenu({ dictionary, userData }),
  ];

  const bottom: (MenuItem | { label: string })[] = [
    {
      label: privacyPolicy,
      path: '/privacy',
    },
    { label: `${version} ${process.env.NEXT_PUBLIC_COMMIT_SHA}` },
  ];

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (
        contentRef.current &&
        !contentRef.current.contains(e.target as Node) &&
        !buttonRef.current?.contains(e.target as Node)
      ) {
        setOpen(false);
      }
    }

    function onKeydown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    }

    window.addEventListener('click', onClick);
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('click', onClick);
      window.removeEventListener('keydown', onKeydown);
    };
  }, []);

  function MenuLink({ label, path }: MenuItem) {
    return (
      <ButtonLink
        href={path}
        className="block p-2 w-full"
        ariaLabel={label}
        onClick={() => setOpen(false)}
        ghost
        round={false}
      >
        {label}
      </ButtonLink>
    );
  }

  return (
    <div className="relative flex items-center gap-2">
      <Hamburger
        ref={buttonRef}
        ariaLabel={menuAriaLabel}
        isOpen={isOpen}
        onClick={() => setOpen((prev) => !prev)}
      />
      <ThemeToggler className="hidden xs:block" />
      <AnimatePresence>
        {isOpen && (
          <>
            <MotionDiv
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.3 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed top-10 bottom-0 right-0 left-0 bg-black"
            />
            <MotionDiv
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.2 }}
              className="fixed top-10 bottom-0 right-0 left-0"
            >
              <div className="w-full max-w-sm mx-auto h-full">
                <div
                  ref={contentRef}
                  className="h-full inline-block pe-6 bg-base-100 border-l border-base-300"
                >
                  <div className="h-full inline-flex flex-col justify-between p-2">
                    <ul className="flex flex-col">
                      <li className="block xs:hidden border-b border-base-300">
                        <ThemeToggler className="w-full" round={false} label />
                      </li>
                      {routes.map((item, index) => (
                        <li
                          key={index}
                          className="border-b border-base-300 last:border-b-0"
                        >
                          <MenuLink {...item} />
                        </li>
                      ))}
                    </ul>
                    <ul className="flex flex-col text-sm">
                      {bottom.map((item, index) => (
                        <li
                          key={index}
                          className="border-b border-base-300 last:border-b-0"
                        >
                          {isMenuItem(item) ? (
                            <MenuLink {...item} />
                          ) : (
                            <div className="p-2">{item.label}</div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </MotionDiv>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};
