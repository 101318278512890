'use client';

import { useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { useNavigate } from '@reshima/navigation-ui';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { deleteLastVisitedList } from '@reshima/user-local-persistence';
import { useClientAuth } from './client-auth';

export function SignOutButton() {
  const name = 'SignOutButton';
  const { signOutButton } = useTranslations()['sign-out'];
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signOut } = useClientAuth();

  async function onSignOutClick() {
    const action = 'SignOut';

    setLoading(true);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    try {
      await deleteLastVisitedList();

      await signOut();

      navigate(`/`);

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
      });
    }
  }

  return loading ? (
    <AiOutlineLoading className="animate-spin text-xl" />
  ) : (
    <Button onClick={onSignOutClick} danger>
      {signOutButton}
    </Button>
  );
}
