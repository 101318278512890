import { useRef } from 'react';
import classNames from 'classnames';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { Link } from '@reshima/navigation-ui';
import { Dropdown, DropdownRef } from '@reshima/pure-ui';
import { List, isListAdmin } from '@reshima/firebase';
import { useClientAuth } from '@reshima/client-auth-ui';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { ShareListLink, ListParticipantsButton } from '@reshima/share-list-ui';
import { ListRenameButton } from './list-rename-button';
import { ListItemsCounts } from '../items/list-items-counts';
import { DownloadListButton } from './download-list-button';
import { DeleteListButton } from './delete-list-button';
import { LeaveListButton } from './leave-list-button';
import { ListSharedBadge } from './list-shared-badge';
import { ListShortcutButton } from './list-shortcut-button';

function stringifyDate(date: Date): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

type ListTileProps = {
  list: List;
  className?: string;
};

export function ListTile({ list, className }: ListTileProps) {
  const name = 'ListTile';
  const { updated, linkAriaLabel, operationsAriaLabel, listNameAriaLabel } =
    useTranslations()['list-tile'];
  const { user } = useClientAuth();

  const { id, name: listName, admins, sharedWith, updatedAt } = list;

  const link = `/list?id=${id}`;

  const dropdownRef = useRef<DropdownRef>(null);

  return (
    <div
      className={classNames('relative', className)}
      data-testid={`list-tile-${id}`}
    >
      <Link
        href={link}
        aria-label={linkAriaLabel}
        className="flex flex-1 flex-col gap-2 py-2 xs:py-3 pe-10"
        onClick={() =>
          trackEvent({
            name,
            action: Action.Click,
            actionModifier: ActionModifier.End,
            properties: { listId: id, link },
          })
        }
      >
        <div className="font-semibold" aria-label={listNameAriaLabel}>
          {listName}
        </div>
        <div className="flex flex-col text-sm">
          <ListItemsCounts listId={id} />
        </div>
        <div className="text-xs">
          {updated}
          {stringifyDate(updatedAt)}
        </div>
      </Link>
      <div className="absolute top-1 end-1">
        <Dropdown
          ref={dropdownRef}
          placement="right"
          ghost
          circle
          buttonClassName="p-1"
          ariaLabel={operationsAriaLabel}
          container={
            <div className="p-1">
              <HiOutlineDotsVertical className="text-xl" />
            </div>
          }
        >
          <div className="flex flex-col">
            <ListRenameButton list={list} />
            <ListShortcutButton list={list} />
            <ShareListLink list={list} />
            {sharedWith.length > 0 || admins.length > 1 ? (
              <ListParticipantsButton listId={id} />
            ) : null}
            <DownloadListButton list={list} />
            {!user ? null : isListAdmin({ list, user }) ? (
              <DeleteListButton list={list} />
            ) : (
              <LeaveListButton list={list} />
            )}
          </div>
        </Dropdown>
      </div>
      <ListSharedBadge list={list} className="absolute bottom-1 end-1" />
    </div>
  );
}
