import { useState, useEffect } from 'react';

type Item = {
  id: string;
};

type ItemMap<T extends Item> = Record<string, T>;

function itemsToMap<T extends Item>(items: T[]): ItemMap<T> {
  return Object.fromEntries(items.map((item) => [item.id, item]));
}

export function useItemTracker<T extends Item>({
  items,
  delay = 0,
  duration = 1500,
}: {
  items?: T[];
  delay?: number;
  duration?: number;
}) {
  const [previousItems, setPreviousItems] = useState<ItemMap<T>>();
  const [newItems, setNewItems] = useState<ItemMap<T>>({});

  useEffect(() => {
    if (!items) {
      return;
    }

    if (!previousItems) {
      setPreviousItems(itemsToMap(items));
      return;
    }

    const currentItems = itemsToMap(items);

    const newItems = itemsToMap(
      Object.values(currentItems).filter((item) => !previousItems[item.id]),
    );

    if (Object.keys(newItems).length > 0) {
      setTimeout(() => setNewItems(newItems), delay);

      setTimeout(() => {
        setPreviousItems(itemsToMap(items));
        setNewItems((_newItems) =>
          itemsToMap(
            Object.values(_newItems).filter(({ id }) => !newItems[id]),
          ),
        );
      }, duration);
    }
  }, [items, previousItems, delay, duration]);

  const currentItems = itemsToMap(items || []);

  const exitingNewItems = Object.values(newItems).filter(
    ({ id }) => currentItems[id],
  );

  return { newItems: exitingNewItems };
}
