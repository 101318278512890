import { useState } from 'react';
import { List, updateListName } from '@reshima/firebase';
import { Button, Input } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';

export function ListRenameModal({ list }: { list: List }) {
  const name = 'ListRenameModal';
  const { renameButton, cancelButton, inputAriaLabel } =
    useTranslations()['list-rename'];
  const [newName, setNewName] = useState(list.name);
  const { closeModal } = useModal();

  async function onRenameClick() {
    const action = Action.Rename;

    const properties = {
      listId: list.id,
      oldName: list.name,
    };

    if (!newName) {
      return;
    }

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      await updateListName({ listId: list.id, newName });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });

      closeModal();
    } catch (error) {
      trackException({
        name,
        action,
        properties,
        start,
        error,
      });
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <Input
        type="text"
        ariaLabel={inputAriaLabel}
        value={newName}
        onChange={setNewName}
        selectOnFocus
        autoFocus
      />
      <div className="flex justify-center gap-2">
        <Button
          onClick={onRenameClick}
          disabled={!newName || newName === list.name}
        >
          {renameButton}
        </Button>
        <Button onClick={closeModal} secondary>
          {cancelButton}
        </Button>
      </div>
    </div>
  );
}
