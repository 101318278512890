import { useState } from 'react';
import { Item, List, checkItems } from '@reshima/firebase';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { ItemActivityType } from '@reshima/list-activity-shared';
import { ListContext } from './list-context';

export function CheckListItemsModal({
  list,
  items,
  addTask,
}: {
  list: List;
  items: Item[];
  addTask: ListContext['addTask'];
}) {
  const name = 'CheckListItemsModal';
  const { checkListButton, cancelButton } = useTranslations()['check-list'];
  const properties = { listId: list.id };

  const [checking, setChecking] = useState(false);
  const { closeModal } = useModal();

  async function onCheckClick() {
    const action = Action.Check;

    setChecking(true);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const itemsCheckedUpdatedAtMap = Object.fromEntries(
        items.map(({ id, checkedUpdatedAt }) => [id, checkedUpdatedAt]),
      );
      const redo = () => checkItems({ list, items, checked: true });
      const undo = () =>
        checkItems({ list, items, checked: false, itemsCheckedUpdatedAtMap });

      await redo();

      addTask({
        redo,
        undo,
        activity: {
          items,
          type: ItemActivityType.checked,
        },
      });

      closeModal();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }

    setChecking(false);
  }

  return (
    <div className="flex justify-center gap-2">
      <Button onClick={onCheckClick} disabled={checking} danger>
        {checkListButton}
      </Button>
      <Button onClick={closeModal}>{cancelButton}</Button>
    </div>
  );
}
