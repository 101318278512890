const finalLetters: { [key: string]: string } = {
  ם: 'מ',
  ן: 'נ',
  ץ: 'צ',
  ף: 'פ',
  ך: 'כ',
};

export function finalLetterToRegular(word: string): string {
  if (!word) {
    return '';
  }

  const lastChar = word.charAt(word.length - 1);

  if (lastChar in finalLetters) {
    return word.slice(0, -1) + finalLetters[lastChar];
  }

  return word;
}
