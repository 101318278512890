import AccountMenu from './account-menu';
import { Brand } from './brand';
import { Menu } from './menu';

export function NavBar() {
  return (
    <>
      <div className="fixed z-10 top-0 w-full h-10 bg-base-200 border-b border-base-300"></div>
      <nav className="sticky z-30 h-10 px-1 xs:px-2 md:px-3">
        <div className="w-full max-w-sm mx-auto h-full flex items-center">
          <div className="flex flex-1 justify-start">
            <Menu />
          </div>
          <div className="flex flex-1 justify-center">
            <Brand />
          </div>
          <div className="flex flex-1 justify-end">
            <AccountMenu />
          </div>
        </div>
      </nav>
    </>
  );
}
