import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { AiOutlineLoading } from 'react-icons/ai';
import { AppUser, Item, List, categorizeItems } from '@reshima/firebase';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { CategoryId, isCategoryValid } from '@reshima/category';
import { batchGetCategories } from '@reshima/category-search';
import { useClientAuth } from '@reshima/client-auth-ui';
import { CategoryLabelIcon } from '@reshima/categories-ui';
import { ListContext } from './list-context';

type RecategorizeItem = {
  itemId: Item['id'];
  itemName: Item['name'];
  existingCategory: CategoryId;
  newCategory: CategoryId;
};

type Column = {
  label: string;
  key: keyof RecategorizeItem;
  render?: (item: RecategorizeItem) => ReactNode;
};

type Props = {
  items: RecategorizeItem[];
  className?: string;
};

function isRecategorizeItem(item: unknown): item is RecategorizeItem {
  if (!item) {
    return false;
  }

  const castedItem = item as RecategorizeItem;

  return !!(
    castedItem.itemId &&
    castedItem.itemName &&
    isCategoryValid(castedItem.existingCategory) &&
    isCategoryValid(castedItem.newCategory)
  );
}

function RecategorizeItemList({ className, items }: Props) {
  const {
    noItemsToCategorize,
    columns: { itemName, existingCategory, newCategory },
  } = useTranslations()['categorize-list-items'];

  const columns: Column[] = [
    { label: itemName, key: 'itemName' },
    {
      label: existingCategory,
      key: 'existingCategory',
      render: ({ existingCategory }) => (
        <CategoryLabelIcon categoryId={existingCategory} />
      ),
    },
    {
      label: newCategory,
      key: 'newCategory',
      render: ({ newCategory }) => (
        <CategoryLabelIcon categoryId={newCategory} />
      ),
    },
  ];

  if (!items.length) {
    return <div>{noItemsToCategorize}</div>;
  }

  return (
    <div
      className={classNames(
        'grid grid-cols-[auto_auto_auto] overflow-y-scroll',
        className,
      )}
    >
      {columns.map(({ label }) => (
        <div key={label} className="font-semibold me-3">
          {label}
        </div>
      ))}
      {items.map((item) => (
        <Fragment key={item.itemId}>
          {columns.map(({ key, render }) => (
            <div key={key} className="me-3">
              {render?.(item) || item[key]}
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  );
}

export function RecategorizeListItemsModal({
  list,
  items,
  addTask,
}: {
  list: List;
  items: Item[];
  addTask: ListContext['addTask'];
}) {
  const name = 'RecategorizeListItemsModal';
  const { categorizeListButton, cancelButton } =
    useTranslations()['categorize-list-items'];
  const [loading, setLoading] = useState(true);
  const [categorizing, setCategorizing] = useState(false);
  const [categorizingItems, setCategorizingItems] = useState<
    RecategorizeItem[]
  >([]);
  const { user } = useClientAuth();
  const { closeModal } = useModal();

  const properties = useMemo(() => ({ listId: list.id }), [list.id]);

  async function recategorizeClick() {
    const action = Action.Recategorize;

    setCategorizing(true);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const existingCategorizedItems = categorizingItems.map(
        ({ itemId, existingCategory }) => ({
          id: itemId,
          categoryId: existingCategory,
        }),
      );

      const categorizedItems = categorizingItems.map(
        ({ itemId, newCategory }) => ({
          id: itemId,
          categoryId: newCategory,
        }),
      );

      const redo = () => categorizeItems({ list, items: categorizedItems });
      const undo = () =>
        categorizeItems({ list, items: existingCategorizedItems });

      await redo();

      addTask({
        undo,
        redo,
      });

      closeModal();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
      setCategorizing(false);
    }
  }

  const fetchCategories = useCallback(
    async ({ user }: { user: AppUser }) => {
      const action = Action.Fetch;

      try {
        setLoading(true);

        const start = trackEvent({
          name,
          action,
          actionModifier: ActionModifier.Start,
          properties,
        });

        const token = await user.firebaseUser.getIdToken();

        const categories = await batchGetCategories({
          searches: items.map(({ name }) => name),
          token,
        });

        const recategorizeItems = items
          .map(({ id, name, categoryId }) => {
            const existingCategory = categoryId;
            const newCategory = categories[name]?.bestMatch?.id;

            return {
              itemId: id,
              itemName: name,
              existingCategory,
              newCategory,
            };
          })
          .filter(isRecategorizeItem)
          .filter(
            ({ existingCategory, newCategory }) =>
              existingCategory !== newCategory,
          );

        setCategorizingItems(recategorizeItems);

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          properties,
        });

        return [];
      }
      setLoading(false);
    },
    [items, properties],
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    fetchCategories({ user });
  }, [fetchCategories, user]);

  return (
    <div className="flex flex-col gap-4 min-h-0">
      {loading ? (
        <div className="flex flex-col items-center">
          <AiOutlineLoading className="text-3xl animate-spin" />
        </div>
      ) : (
        <RecategorizeItemList className="min-h-0" items={categorizingItems} />
      )}
      <div className="flex justify-center gap-2">
        <Button
          onClick={recategorizeClick}
          disabled={categorizing || loading}
          danger
        >
          {categorizeListButton}
        </Button>
        <Button onClick={closeModal}>{cancelButton}</Button>
      </div>
    </div>
  );
}
