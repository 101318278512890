const materLectionisLetters = ['א', 'ה', 'ו', 'י'];

const materLectionisLettersRegex = new RegExp(
  `(${materLectionisLetters.join('|')})$`,
);

export function removeLastMaterLectionis(word: string): string {
  if (!word) {
    return '';
  }

  return word.replace(materLectionisLettersRegex, '');
}
