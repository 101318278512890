import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import {
  addItem,
  deleteItem,
  List,
  maxItemNameLength,
  restoreItem,
  suggestCategorizedItem,
} from '@reshima/firebase';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { debouncedCallback, Units } from '@reshima/shared';
import { CategoryId } from '@reshima/category';
import { CategoriesSelect } from '@reshima/categories-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { ItemActivityType } from '@reshima/list-activity-shared';
import {
  cleanCountFromItemName,
  cleanUnitFromItemName,
  extractCount,
  extractUnit,
  maxItemCount,
} from '../shared/counts-and-units';
import { useListContext } from '../lists/list-context';
import { UnitSelect } from './unit-select';
import { Pencil } from './pencil';

type Props = {
  list: List;
  className?: string;
  onItemNameChange: (itemName: string) => void;
};

export type AddItemFormHandler = {
  reset: () => void;
};

export const AddItemForm = forwardRef(function AddItemForm(
  { list, className, onItemNameChange }: Props,
  ref: React.Ref<AddItemFormHandler>,
) {
  const name = 'AddItemForm';
  const { addItemButton, addItemPlaceholder, automaticCategorySelection } =
    useTranslations()['add-item'];

  const { id: listId } = list;

  const [itemName, setItemName] = useState('');
  const [cleanedItemName, _setCleanedItemName] = useState('');
  const [count, setCount] = useState(1);
  const [unit, setUnit] = useState(Units.pcs);
  const [categoryId, setCategoryId] = useState<CategoryId>();
  const [isManualUpdated, setIsManualUpdated] = useState(false);
  const [isCategoryManualUpdated, setIsCategoryManualUpdated] = useState(false);
  const [isSuggestingCategory, setIsSuggestingCategory] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const counterRef = useRef<HTMLInputElement>(null);
  const cleanedItemNameRef = useRef('');
  const suggestedCategoryItemNameRef = useRef('');
  const { userData } = useClientAuth();
  const { addTask } = useListContext();

  const isCounterFocus = counterRef.current === document.activeElement;
  const countValue = count >= 1 ? count : isCounterFocus ? '' : 1;

  const setCleanedItemName = useCallback(
    (itemName: string) => {
      _setCleanedItemName(itemName);
      onItemNameChange(itemName);
      cleanedItemNameRef.current = itemName;
    },
    [onItemNameChange],
  );

  const reset = useCallback(() => {
    setCleanedItemName('');
    setItemName('');
    setCount(1);
    setUnit(Units.pcs);
    setCategoryId(undefined);
    setIsManualUpdated(false);
    setIsCategoryManualUpdated(false);
    setIsSuggestingCategory(false);
  }, [setCleanedItemName]);

  // TODO: fix this
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSuggestCategory = useCallback(
    debouncedCallback<string>({
      callback: async (itemName: string) => {
        try {
          const categoryId = await suggestCategorizedItem({ itemName });

          if (itemName === cleanedItemNameRef.current) {
            setCategoryId(categoryId);
            setIsSuggestingCategory(false);
            suggestedCategoryItemNameRef.current = itemName;
          }
        } catch (error) {
          setIsSuggestingCategory(false);
          trackException({
            name,
            action: 'SuggestCategory',
            error,
            properties: { itemName },
          });
        }
      },
      delay: 300,
    }),
    [],
  );

  const suggestCategory = useCallback(
    (itemName: string) => {
      if (!itemName) {
        setIsSuggestingCategory(false);
        setCategoryId(undefined);
      } else {
        setIsSuggestingCategory(true);
        debouncedSuggestCategory(itemName);
      }
    },
    [debouncedSuggestCategory],
  );

  const onCountChange = useCallback((count: number) => {
    setCount(count);
    setIsManualUpdated(true);
  }, []);

  const onUnitChange = useCallback((unit: Units) => {
    setUnit(unit);
    setIsManualUpdated(true);
  }, []);

  const onCategoryChange = useCallback(
    (categoryId: CategoryId) => {
      setCategoryId(categoryId);
      setIsCategoryManualUpdated(categoryId ? true : false);
      if (!categoryId) {
        suggestCategory(cleanedItemName);
      }
    },
    [cleanedItemName, suggestCategory],
  );

  const updateItemName = useCallback(
    ({
      itemName,
      cleanedItemName,
    }: {
      itemName: string;
      cleanedItemName?: string;
    }) => {
      cleanedItemName = (cleanedItemName || itemName).trim();
      setItemName(itemName);
      setCleanedItemName(cleanedItemName);
      if (!isCategoryManualUpdated) {
        suggestCategory(cleanedItemName);
      }
    },
    [suggestCategory, setCleanedItemName, isCategoryManualUpdated],
  );

  const onNameChange = useCallback(
    (itemName: string) => {
      const { parseItemCount, parseItemUnit } =
        userData?.settings.addingItem.autoComplete || {};

      if (isManualUpdated) {
        updateItemName({ itemName });
        return;
      }

      let cleanedItemName = itemName;

      if (parseItemCount) {
        const itemCount = extractCount({ itemName });
        setCount(itemCount || 1);

        if (itemCount) {
          cleanedItemName = cleanCountFromItemName({
            itemName: cleanedItemName,
            itemCount,
          });
        }
      }

      if (parseItemUnit) {
        const itemUnit = extractUnit({ itemName });
        setUnit(itemUnit);

        cleanedItemName = cleanUnitFromItemName({
          itemName: cleanedItemName,
          itemUnit,
        });
      }

      updateItemName({ itemName, cleanedItemName });
    },
    [
      userData?.settings.addingItem.autoComplete,
      isManualUpdated,
      updateItemName,
    ],
  );

  async function onAddItemClick() {
    const action = 'AddItem';

    const suggestedCategoryItemName = suggestedCategoryItemNameRef.current;

    const isSuggestedCategory = suggestedCategoryItemName === cleanedItemName;

    const item = {
      name: cleanedItemName,
      count,
      unit,
      ...(isCategoryManualUpdated || isSuggestedCategory ? { categoryId } : {}),
    };

    const properties = {
      listId,
      item,
      isManualUpdated,
      isCategoryManualUpdated,
      suggestedCategoryItemName,
      isSuggestedCategory,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    reset();

    if (!cleanedItemName) {
      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Skip,
        properties,
        start,
      });

      return;
    }

    try {
      const addedItem = await addItem({
        list,
        item,
      });

      addTask({
        undo: () => deleteItem({ listId, item: addedItem }),
        redo: () => restoreItem({ listId, item: addedItem }),
        activity: {
          type: ItemActivityType.added,
          items: [addedItem],
        },
      });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  useEffect(() => inputRef.current?.focus(), []);

  useImperativeHandle(ref, () => ({
    reset,
  }));

  return (
    <div
      className={classNames(
        'flex w-full flex-col xs:flex-row gap-2',
        className,
      )}
      data-testid="add-item-form"
    >
      <div
        className={classNames(
          'w-full flex-1',
          'text-lg',
          'flex items-center',
          'border rounded-lg bg-base-100 border-base-300',
          'focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500',
        )}
      >
        <CategoriesSelect
          categoryId={categoryId}
          round={false}
          chevron={false}
          onCategoryIdChange={onCategoryChange}
          placement="left"
          className={classNames(
            'box-content w-6 h-6 p-1',
            'flex items-center justify-center',
            'border border-transparent rounded-s-md',
            {
              'animate-[categoryPulse_1s_cubic-bezier(0.4,0,0.6,1)_infinite]':
                isSuggestingCategory,
            },
          )}
          emptyOption={{
            icon: '✨',
            label: automaticCategorySelection,
          }}
          placeholder={<Pencil className="w-full scale-x-[-1]" />}
        />
        <div className="flex-1">
          <input
            ref={inputRef}
            type="text"
            value={itemName}
            onChange={({ target: { value } }) => onNameChange(value)}
            onKeyDown={({ key }) => {
              if (key === 'Enter' && itemName) {
                onAddItemClick();
              }
            }}
            className={classNames(
              'w-full',
              'py-1 px-2',
              'bg-transparent',
              'border border-base-300 focus:border-base-300',
              'border-t-transparent border-b-transparent',
              'focus:border-t-transparent focus:border-b-transparent',
              'bg-base-100 focus:ring-0',
              {
                'border-e-transparent focus:border-e-transparent':
                  !itemName.length,
              },
            )}
            placeholder={addItemPlaceholder}
            maxLength={maxItemNameLength}
            autoFocus
          />
        </div>
        {itemName.length ? (
          <div className="flex flex-shrink">
            <input
              ref={counterRef}
              type="number"
              inputMode="numeric"
              className={classNames(
                'box-content',
                'py-1 px-1.5 xs:px-1.5',
                'text-center',
                'bg-transparent',
                'rounded-md border-none focus-within:ring-2 focus-within:ring-blue-500',
                '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
              )}
              style={{
                width: `${(countValue.toString().length + 1) / 2}em`,
              }}
              value={countValue}
              onFocus={(e) => e.target.select()}
              onBlur={() => {
                if (count < 1) return onCountChange(1);
                if (count > maxItemCount) return onCountChange(maxItemCount);
              }}
              onChange={(e) => {
                const value = +e.target.value;
                if (value > maxItemCount) return onCountChange(maxItemCount);
                return onCountChange(value);
              }}
              onKeyDown={({ key }) => {
                if (key === 'Enter' && itemName) {
                  onAddItemClick();
                  inputRef.current?.focus();
                }
              }}
            />
            <UnitSelect
              unit={unit}
              buttonClassName={classNames(
                'px-1 xs:px-1.5 py-0.5',
                'border border-transparent',
              )}
              onChange={onUnitChange}
              chevron
              tight
            />
          </div>
        ) : null}
      </div>
      <Button
        className="text-lg py-0.5 px-1 xs:px-2"
        tight
        disabled={!cleanedItemName}
        onClick={() => {
          onAddItemClick();
          inputRef.current?.focus();
        }}
      >
        {addItemButton}
      </Button>
    </div>
  );
});
