const numbers = [
  'רבע',
  'שליש',
  'חצי',
  'אחד',
  'שניים',
  'שלושה',
  'ארבעה',
  'חמישה',
  'שישה',
  'שבעה',
  'שמונה',
  'תשעה',
  'עשרה',
  'אחת',
  'שתיים',
  'שלוש',
  'ארבע',
  'חמש',
  'שש',
  'שבע',
  'שמונה',
  'תשע',
  'עשר',
  'עשרה',
  'עשרים',
  'שלושים',
  'ארבעים',
  'חמישים',
  'שישים',
  'שבעים',
  'שמונים',
  'תשעים',
  'מאה',
  'מאתיים',
  'מאות',
  'אלף',
  'אלפיים',
  'אלפים',
  'שלושת',
  'ארבעת',
  'חמשת',
  'ששת',
  'שבעת',
  'שמונת',
  'תשעת',
  'שלישית',
  'רביעית',
  'חמישית',
  'שישית',
  'שביעית',
  'שמינית',
  'תשיעית',
  'שלישיית',
  'רביעיית',
  'חמישיית',
  'שישיית',
  'שביעיית',
  'שמיניית',
  'תשיעיית',
  'שלישיה',
  'רביעיה',
  'חמישיה',
  'שישיה',
  'שביעיה',
  'שמיניה',
  'תשיעיה',
  'שלישייה',
  'רביעייה',
  'חמישייה',
  'שישייה',
  'שביעייה',
  'שמינייה',
  'תשיעייה',
  'מיליון',
  'מיליונים',
  'מיליארד',
  'מיליארדים',
]
  .map((number) => [number, `ו${number}`])
  .flat();

export const numbersWords = [...numbers, 'מספר', 'מספרים', 'מס'];
