import { useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { useTranslations } from '@reshima/translations-ui';
import { getUncheckedItemsCount } from '@reshima/firebase';

export function ListItemsCounts({ listId }: { listId: string }) {
  const { noUncheckedItems, uncheckedItem, uncheckedItems } =
    useTranslations()['list-counts'];
  const [loading, setLoading] = useState(true);
  const [uncheckedItemCounts, setUncheckedItemCounts] = useState<number>();

  useEffect(() => {
    async function fetchListCounts() {
      const uncheckedCounts = await getUncheckedItemsCount({ listId });

      setLoading(false);
      setUncheckedItemCounts(uncheckedCounts);
    }

    fetchListCounts();
  }, [listId]);

  function getUncheckedText(uncheckedItemCounts = 0) {
    if (uncheckedItemCounts === 0) {
      return noUncheckedItems;
    }

    if (uncheckedItemCounts === 1) {
      return uncheckedItem;
    }

    return `${uncheckedItemCounts} ${uncheckedItems}`;
  }

  return loading ? (
    <AiOutlineLoading className="text-xl animate-spin" />
  ) : (
    getUncheckedText(uncheckedItemCounts)
  );
}
