import { AiOutlineDelete } from 'react-icons/ai';
import { Button } from '@reshima/pure-ui';
import { List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { DeleteListModal } from './delete-list-modal';

export function DeleteListButton({
  list,

  onDelete,
}: {
  list: List;

  onDelete?: () => void;
}) {
  const name = 'DeleteListButton';
  const { heading, description, button } = useTranslations()['delete-list'];
  const { showModal } = useModal();

  const properties = { listId: list.id };

  function onDeleteButtonClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      description,
      content: <DeleteListModal list={list} onDelete={onDelete} />,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button
      className="flex items-center gap-2"
      onClick={onDeleteButtonClick}
      ghost
    >
      <AiOutlineDelete className="text-lg" />
      {button}
    </Button>
  );
}
