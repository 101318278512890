import { FirebaseApp } from 'firebase/app';
import {
  Auth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  initializeAuth,
} from 'firebase/auth';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';

const name = 'FirebaseAuth';

let authApp: Auth;

export function initAuth({ app }: { app: FirebaseApp }) {
  const action = 'Initialize';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    authApp = initializeAuth(app, {
      persistence: [browserLocalPersistence],
      popupRedirectResolver: browserPopupRedirectResolver,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
  }
}

export function getAuthApp() {
  return authApp;
}
