import { ReactNode } from 'react';
import { useClientAuth } from '@reshima/client-auth-ui';
import { Participant, UserData } from '@reshima/firebase';
import { MultiSelect } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';

type Props = {
  participantsIds?: string[];
  participants: Participant[];
  containerClassName?: string;
  buttonClassName?: string;
  secondary?: boolean;
  tight?: boolean;
  disabled?: boolean;
  onChange: (participantsIds: string[]) => void;
};

export function ParticipantsMultiSelect({
  participantsIds,
  participants,
  containerClassName,
  buttonClassName,
  tight,
  secondary,
  disabled,
  onChange,
}: Props) {
  const { allParticipantsLabel, currentParticipantName, noParticipantName } =
    useTranslations()['participants-multiselect'];

  const { userData } = useClientAuth();

  function getParticipantLabel({
    participant: { id, name, index },
    userData,
  }: {
    participant: Participant;
    userData?: UserData;
  }): ReactNode {
    if (id === userData?.id) {
      return <span className="italic">{currentParticipantName}</span>;
    }

    return (
      userData?.contacts[id]?.name || name || `${noParticipantName} #${index}`
    );
  }

  return (
    <MultiSelect
      placement="left"
      secondary={secondary}
      tight={tight}
      ids={participantsIds}
      allSelectedLabel={allParticipantsLabel}
      containerClassName={containerClassName}
      buttonClassName={buttonClassName}
      disabled={disabled}
      onChange={(types) => onChange(types as string[])}
      options={participants.map((participant) => {
        const label = getParticipantLabel({
          participant,
          userData,
        });

        return {
          id: participant.id,
          label,
        };
      })}
    />
  );
}
