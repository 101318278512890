'use client';

import { useEffect, useRef } from 'react';
import classNames from 'classnames';

type Props = {
  type: 'text';
  id?: string;
  ariaLabel: string;
  value?: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  selectOnFocus?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  dir?: 'ltr' | 'rtl';
  readOnly?: boolean;
  onChange?: (newName: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export function Input({
  type,
  id,
  ariaLabel,
  value = '',
  className,
  placeholder,
  maxLength,
  selectOnFocus,
  disabled,
  autoFocus,
  dir,
  readOnly,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    if (selectOnFocus) {
      inputRef.current?.select();
    }

    onFocus?.();
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <input
      ref={inputRef}
      type={type}
      id={id}
      aria-label={ariaLabel}
      value={value}
      dir={dir}
      readOnly={readOnly}
      className={classNames(
        'py-1 px-2 bg-base-100 border rounded-md shadow-sm',
        className,
      )}
      onKeyDown={(event) => onKeyDown?.(event)}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      onChange={(event) => onChange?.(event.target.value)}
      onFocus={handleFocus}
      onBlur={onBlur}
    />
  );
}
