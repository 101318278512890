'use client';
import { useState } from 'react';
import { HiOutlineClipboard, HiOutlineClipboardCheck } from 'react-icons/hi';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useTranslations } from '@reshima/translations-ui';
import { Button } from '@reshima/pure-ui';

export function CopyButton({ text }: { text: string }) {
  const name = 'CopyButton';
  const { copyButton, copiedButton } = useTranslations()['copy-button'];

  const [isTextCopied, setIsTextCopied] = useState(false);

  function onCopyButtonClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    try {
      navigator.clipboard.writeText(text);
      setIsTextCopied(true);
      setTimeout(() => setIsTextCopied(false), 2000);

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
      });
    }
  }

  return (
    <Button onClick={onCopyButtonClick}>
      <div className="flex gap-1 items-center justify-center">
        {isTextCopied ? (
          <>
            {copiedButton}
            <HiOutlineClipboardCheck className="text-2xl" />
          </>
        ) : (
          <>
            {copyButton}
            <HiOutlineClipboard className="text-2xl" />
          </>
        )}
      </div>
    </Button>
  );
}
