import { useState } from 'react';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { updateListDisableActivity } from '@reshima/firebase';

type Props = {
  listId: string;
};

export function DisableListActivityModal({ listId }: Props) {
  const name = 'DisableListActivityModal';
  const { disableListActivityButton, cancelButton, description, error } =
    useTranslations()['disable-list-activity-modal'];

  const properties = { listId };

  const [disabling, setDisabling] = useState(false);
  const [isError, setIsError] = useState(false);
  const { closeModal } = useModal();

  async function onDisableClick() {
    const action = Action.Disable;

    setDisabling(true);
    setIsError(false);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      await updateListDisableActivity({ listId, disableActivity: true });

      closeModal();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      setIsError(true);
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }

    setDisabling(false);
  }

  function onCancelClick() {
    trackEvent({
      name,
      action: Action.Cancel,
      actionModifier: ActionModifier.End,
      properties,
    });

    closeModal();
  }

  return (
    <div className="max-w-sm flex flex-col gap-4">
      <p className="items-center">{description}</p>
      {isError && <p className="items-center text-red-500">{error}</p>}
      <div className="flex justify-center gap-2">
        <Button onClick={onDisableClick} disabled={disabling} danger>
          {disableListActivityButton}
        </Button>
        <Button onClick={onCancelClick}>{cancelButton}</Button>
      </div>
    </div>
  );
}
