export function formatDate(date: Date): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

function zeroLead(value: number): string {
  return value.toString().padStart(2, '0');
}

export function formatTime(date: Date): string {
  return `${zeroLead(date.getHours())}:${zeroLead(date.getMinutes())}`;
}

export function formatDateTime(date: Date): string {
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function daysDiff(date1: Date, date2: Date): number {
  return Math.floor(
    (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24),
  );
}
