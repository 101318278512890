import { NewListItemActivityV2 } from '@reshima/list-activity-shared';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';

export type LocalStorageActivities = {
  listId: string;
  session: string;
  activities: NewListItemActivityV2[];
};

const activitiesKeyPrefix = 'activities';

let currentSession = Date.now().toString();

function getSessionActivitiesKey({
  listId,
  session,
}: {
  listId: string;
  session: string;
}) {
  return `${activitiesKeyPrefix}:${listId}:${session}`;
}

function isLocalStorageActivities(
  value: unknown,
): value is LocalStorageActivities {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const { listId, session, activities } = value as LocalStorageActivities;

  return (
    typeof listId === 'string' &&
    typeof session === 'string' &&
    Array.isArray(activities)
  );
}

export function resetCurrentSession() {
  currentSession = Date.now().toString();
}

export function getPreviousActivitiesFromLocalStorage(): LocalStorageActivities[] {
  const name = 'getPreviousActivitiesFromLocalStorage';
  const action = Action.Get;

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  const activities: LocalStorageActivities[] = [];

  try {
    Object.entries(localStorage)
      .filter(
        ([key]) =>
          key.startsWith(activitiesKeyPrefix) && !key.endsWith(currentSession),
      )
      .forEach(([key, value]) => {
        try {
          const parsed = JSON.parse(value);

          if (!isLocalStorageActivities(parsed)) {
            throw new Error('Invalid activity format');
          }

          activities.push(parsed);
        } catch (error) {
          trackException({
            name,
            action,
            error,
            properties: {
              key,
            },
          });

          localStorage.removeItem(key);
        }
      });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties: {
        activitiesLength: activities.length,
      },
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
  }

  return activities;
}

export function setActivitiesInLocalStorage({
  listId,
  session,
  activities,
}: {
  listId: string;
  session: string;
  activities: NewListItemActivityV2[];
}) {
  const key = getSessionActivitiesKey({ listId, session });

  const value: LocalStorageActivities = {
    listId,
    session,
    activities,
  };

  localStorage.setItem(key, JSON.stringify(value));
}

export function setCurrentActivitiesInLocalStorage({
  listId,
  activities,
}: {
  listId: string;
  activities: NewListItemActivityV2[];
}) {
  setActivitiesInLocalStorage({ listId, session: currentSession, activities });
}

export function deleteActivitiesFromLocalStorage({
  listId,
  session,
}: {
  listId: string;
  session: string;
}) {
  localStorage.removeItem(getSessionActivitiesKey({ listId, session }));
}
