import { AiOutlineCheck } from 'react-icons/ai';
import { Button } from '@reshima/pure-ui';
import { Item, List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { CheckListItemsModal } from './check-list-items-modal';
import { useListContext } from './list-context';

export function CheckListItemsButton({
  list,
  items,
}: {
  list: List;
  items: Item[];
}) {
  const name = 'CheckListButton';
  const { button, heading, description } = useTranslations()['check-list'];

  const properties = { listId: list.id };
  const { showModal } = useModal();
  const { addTask } = useListContext();

  function onCheckButtonClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      description,
      content: (
        <CheckListItemsModal list={list} items={items} addTask={addTask} />
      ),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button
      className="flex items-center gap-2"
      onClick={onCheckButtonClick}
      disabled={!items.length}
      ghost
    >
      <AiOutlineCheck className="text-lg" />
      {button}
    </Button>
  );
}
