'use client';
import { useTranslations } from '@reshima/translations-ui';
import { List } from '@reshima/firebase';
import { SignIn, useClientAuth } from '@reshima/client-auth-ui';
import { isChromeExtension } from '@reshima/shared';
import { CopyButton, isShareAvailable, ShareButton } from '@reshima/shared-ui';
import { Input } from '@reshima/pure-ui';

function isDesktop(): boolean {
  return !('ontouchstart' in window);
}

function getShareLink({ shareLink }: List): string {
  const path = `/join?key=${shareLink}`;

  if (isChromeExtension()) {
    return `https://reshima.co.il${path}`;
  }

  return `${window.location.origin}${path}`;
}

export function ShareListLinkModal({ list }: { list: List }) {
  const {
    sharedLinkAriaLabel,
    signInDescription,
    sharingDescription,
    sharedLinkDescription,
    shareListLinkTitle,
  } = useTranslations()['share-list'];

  const shareListLink = getShareLink(list);

  const { user } = useClientAuth();

  if (!user?.isSignedIn) {
    return (
      <div className="flex flex-col items-center">
        <p className="py-2">{signInDescription}</p>
        <SignIn />
      </div>
    );
  }

  function ShareListLinkButton() {
    if (isShareAvailable() && !isDesktop()) {
      return (
        <ShareButton
          shareData={{
            title: shareListLinkTitle,
            url: shareListLink,
          }}
        />
      );
    }

    return <CopyButton text={shareListLink} />;
  }

  return (
    <div className="flex flex-col">
      <p className="py-2">{sharingDescription}</p>
      <div className="flex flex-col gap-1">
        <p>{sharedLinkDescription}</p>
        <div className="flex flex-col xs:flex-row gap-1 xs:gap-2">
          <Input
            type="text"
            ariaLabel={sharedLinkAriaLabel}
            className="w-full"
            value={shareListLink}
            dir="ltr"
            readOnly
            selectOnFocus
          />
          <ShareListLinkButton />
        </div>
      </div>
    </div>
  );
}
