import { MultiSelect } from '@reshima/pure-ui';
import { ItemActivityType } from '@reshima/list-activity-shared';
import { useTranslations } from '@reshima/translations-ui';

type Props = {
  types?: ItemActivityType[];
  containerClassName?: string;
  buttonClassName?: string;
  secondary?: boolean;
  tight?: boolean;
  onChange: (types: ItemActivityType[]) => void;
};

export function ListActivityTypesMultiSelect({
  types,
  containerClassName,
  buttonClassName,
  tight,
  secondary,
  onChange,
}: Props) {
  const { typesLabels, allTypesLabel } =
    useTranslations()['list-activity-types-multiselect'];

  return (
    <MultiSelect
      placement="left"
      secondary={secondary}
      tight={tight}
      ids={types}
      allSelectedLabel={allTypesLabel}
      containerClassName={containerClassName}
      buttonClassName={buttonClassName}
      onChange={(types) => onChange(types as ItemActivityType[])}
      options={Object.values(ItemActivityType).map((id) => ({
        id,
        label: typesLabels[id],
      }))}
    />
  );
}
