import { Item } from '@reshima/firebase';
import {
  ItemActivityType,
  NewListItemActivityV2,
} from '@reshima/list-activity-shared';
import { randomHash, Units } from '@reshima/shared';

export function itemToNewListItemActivity({
  item,
  type,
  createdAt,
}: {
  item: Item;
  type: ItemActivityType;
  createdAt: number;
}): NewListItemActivityV2 {
  const itemActivity: NewListItemActivityV2 = {
    id: randomHash(16),
    createdAt,
    type,
    itemId: item.id,
    itemName: item.name,
    itemCategoryId: item.categoryId,
    itemUnit: item.unit || Units.pcs,
    itemCount: item.count || 1,
  };

  return itemActivity;
}
