'use client';
import { debouncedCallback } from '@reshima/shared';
import { useCallback, useRef, useState } from 'react';
import { EditableText } from './editable-text';

type Params = {
  value?: string;
  id?: string;
  delay: number;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  maxLength?: number;
  ariaLabel: string;
  selectOnFocus?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export function DebouncedInput({
  id,
  value,
  placeholder,
  className,
  delay,
  disabled,
  maxLength,
  ariaLabel,
  selectOnFocus,
  onChange,
  onFocus,
  onBlur,
}: Params) {
  const [editingValue, setEditingValue] = useState(value);
  const isPendingChangeRef = useRef(false);

  const triggerChangeIfNeed = useCallback(
    (value: string) => {
      if (!isPendingChangeRef.current) return;
      isPendingChangeRef.current = false;
      onChange(value);
    },
    [onChange],
  );

  // TODO: fix eslint warning
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedChange = useCallback(
    debouncedCallback<string>({
      callback: triggerChangeIfNeed,
      delay,
    }),
    [onChange, delay],
  );

  const handleChange = (value: string) => {
    setEditingValue(value);
    isPendingChangeRef.current = true;
    handleDebouncedChange(value);
  };

  return (
    <EditableText
      id={id}
      className={className}
      value={isPendingChangeRef.current ? editingValue : value}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={maxLength}
      ariaLabel={ariaLabel}
      selectOnFocus={selectOnFocus}
      onChange={handleChange}
      onFocus={() => {
        isPendingChangeRef.current = true;
        onFocus?.();
      }}
      onBlur={() => {
        if (editingValue !== value) {
          triggerChangeIfNeed(editingValue || '');
        }
        onBlur?.();
      }}
    />
  );
}
