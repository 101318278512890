'use client';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { Dropdown } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { List } from '@reshima/firebase';
import { DeleteListActivityButton } from './delete-list-activity-button';
import { ToggleListActivityButton } from './toggle-list-activity-button';

type Props = {
  list: List;
  disabled?: boolean;
  onDelete: () => void;
};

export function ListActivityActionsDropdown({
  list,
  disabled,
  onDelete,
}: Props) {
  const { ariaLabel } = useTranslations()['list-activity-actions-dropdown'];

  return (
    <Dropdown
      ghost
      circle
      disabled={disabled}
      ariaLabel={ariaLabel}
      placement="right"
      dataTestId="list-activity-actions-menu-content"
      container={
        <div className="p-1.5">
          <HiOutlineDotsVertical className="text-2xl" />
        </div>
      }
    >
      <div className="flex flex-col items-stretch">
        <ToggleListActivityButton list={list} />
        <DeleteListActivityButton listId={list.id} onDelete={onDelete} />
      </div>
    </Dropdown>
  );
}
