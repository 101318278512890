export const adjectiveWords = [
  'קצת',
  'מעט',
  'הרבה',
  'מאוד',
  'רגיל',
  'רגילה',
  'רגילים',
  'רגילות',
  'במיוחד',
  'מיוחד',
  'מיוחדת',
  'מיוחדים',
  'מיוחדות',
  'חדש',
  'חדשה',
  'חדשים',
  'חדשות',
  'ישן',
  'ישנה',
  'ישנים',
  'ישנות',
  'זול',
  'זולה',
  'זולים',
  'זולות',
  'יקר',
  'יקרה',
  'יקרים',
  'יקרות',
  'עם',
  'בתוספת',
  'בטעם',
  'מועשר',
  'בסגנון',
  'שטוף',
  'שטופה',
  'שטופות',
  'בצורה',
  'בצורת',
  'בצורות',
  'טבעי',
  'טבעיים',
  'טבעים',
  'טבעית',
  'טבעיות',
  'פלסטיק',
  'מילוי',
  'במילוי',
  'במילויים',
];
