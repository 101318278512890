'use client';
import { useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { MotionDiv } from '@reshima/shared-ui';
import { createList } from '@reshima/firebase';
import { useNavigate } from '@reshima/navigation-ui';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';

export function CreateListButton() {
  const name = 'CreateListButton';
  const { button } = useTranslations()['create-list'];
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useClientAuth();

  async function onClick() {
    const action = Action.Click;
    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    setLoading(true);

    try {
      const list = await createList();

      const url = `/list?id=${list.id}`;

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
        properties: {
          url,
        },
      });

      navigate(url);
    } catch (error) {
      trackException({
        name,
        action,
        error,
        start,
      });
      setLoading(false);
    }
  }

  return loading ? (
    <AiOutlineLoading className="text-xl animate-spin" />
  ) : (
    user && (
      <MotionDiv
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
        }}
      >
        <Button disabled={!user} onClick={onClick}>
          {button}
        </Button>
      </MotionDiv>
    )
  );
}
