import { useState } from 'react';
import { Item, List, deleteItems, restoreItems } from '@reshima/firebase';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { ItemActivityType } from '@reshima/list-activity-shared';
import { ListContext } from './list-context';

export function DeleteListItemsModal({
  list,
  items,
  addTask,
}: {
  list: List;
  items: Item[];
  addTask: ListContext['addTask'];
}) {
  const name = 'DeleteListItemsModal';
  const { deleteListButton, cancelButton } =
    useTranslations()['delete-list-items'];
  const properties = { listId: list.id };

  const [deleting, setDeleting] = useState(false);
  const { closeModal } = useModal();

  async function onDeleteClick() {
    const action = Action.Delete;

    setDeleting(true);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const redo = () => deleteItems({ list, items });
      const undo = () => restoreItems({ list, items });

      await redo();

      addTask({
        undo,
        redo,
        activity: {
          items,
          type: ItemActivityType.deleted,
        },
      });

      closeModal();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
      setDeleting(false);
    }
  }

  return (
    <div className="flex justify-center gap-2">
      <Button onClick={onDeleteClick} disabled={deleting} danger>
        {deleteListButton}
      </Button>
      <Button onClick={closeModal}>{cancelButton}</Button>
    </div>
  );
}
