'use client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ProviderId, randomString } from '@reshima/shared';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { hashString } from '@reshima/cryptography-browser';
import { signInWithCredential } from '@reshima/firebase';
import { Message } from './models';
import { isTokenMessage } from './is';
import { isIOSSignInAvailable } from './is-ios-sign-in-available';

export function useIOSAuth() {
  const name = 'UseIOSAuth';
  const action = Action.SignIn;

  const [isIOSSignInError, setIsIOSSignInError] = useState(false);
  const [isIOSSigningIn, setIsIOSSigningIn] = useState(false);
  const [iOSSigningProviderId, setIOSSigningProviderId] =
    useState<ProviderId>();
  const rawNonce = useRef<string>('');

  const listenForIOSAuth = useCallback(() => {
    const event = 'ios-auth';

    if (!isIOSSignInAvailable()) {
      return;
    }

    trackEvent({
      name,
      action: Action.Listen,
      actionModifier: ActionModifier.Start,
    });

    async function handleIOSMessage(event: CustomEvent<Message>) {
      const action = Action.OnMessage;

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
      });

      try {
        if (!isTokenMessage(event.detail)) {
          throw new Error('Invalid message received');
        }

        await signInWithCredential({
          ...event.detail,
          rawNonce: rawNonce.current,
        });

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          properties: { providerId: event.detail.providerId },
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          error,
          start,
        });
        setIsIOSSignInError(true);
      } finally {
        setIsIOSSigningIn(false);
        setIOSSigningProviderId(undefined);
      }
    }

    window.addEventListener(
      event,
      handleIOSMessage as unknown as EventListener,
    );

    return () =>
      window.removeEventListener(
        event,
        handleIOSMessage as unknown as EventListener,
      );
  }, []);

  const iOSSignIn = useCallback(
    async ({ providerId }: { providerId: ProviderId }) => {
      setIsIOSSigningIn(true);
      setIOSSigningProviderId(providerId);
      setIsIOSSignInError(false);

      const properties = { providerId };

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      if (providerId === ProviderId.google) {
        window.webkit.messageHandlers.googleSignIn.postMessage(null);
        return;
      }

      if (providerId === ProviderId.apple) {
        rawNonce.current = randomString(32);
        const hashedNonce = await hashString(rawNonce.current);
        window.webkit.messageHandlers.appleSignIn.postMessage(hashedNonce);
        return;
      }

      trackException({
        name,
        action,
        properties,
        error: new Error('Invalid providerId'),
      });
    },
    [action],
  );

  useEffect(listenForIOSAuth, [listenForIOSAuth]);

  return {
    isIOSSignInError,
    isIOSSigningIn,
    iOSSigningProviderId,
    iOSSignIn,
    isIOSSignInAvailable: isIOSSignInAvailable(),
  };
}
