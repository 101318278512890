import { UserMessage } from '../models';
import { getUserMessage, setUserMessage } from '../local-storage';

export function getReferralUserMessage(): UserMessage | undefined {
  return getUserMessage({ messageId: 'referral' });
}

function setReferralUserMessage(userMessage: UserMessage): void {
  setUserMessage({ messageId: 'referral', userMessage });
}

export function setReferralUserMessageHiddenAt(): void {
  setReferralUserMessage({ hiddenAt: new Date() });
}
