import { CategoryId, getCategoryIcon } from '@reshima/category';
import { Button } from '@reshima/pure-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useTranslations } from '@reshima/translations-ui';
import { useModal } from '@reshima/modals-ui';
import { CategoriesPickerModal } from './categories-picker-modal';

export function CategoriesPickerButton({
  categoryId,
  categoriesOrder,
  onCategoryIdSelect,
}: {
  categoryId: CategoryId;
  categoriesOrder?: CategoryId[];
  onCategoryIdSelect: (categoryId: CategoryId) => void;
}) {
  const name = 'CategoriesPickerButton';

  const dictionary = useTranslations();
  const iconTitle = dictionary.categories[categoryId];

  const { heading } = dictionary['update-list-item-category'];

  const { showModal } = useModal();

  function onClick() {
    const action = Action.Click;

    const properties = {
      categoryId,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      content: (
        <CategoriesPickerModal
          categoryId={categoryId}
          categoriesOrder={categoriesOrder}
          onCategoryIdSelect={onCategoryIdSelect}
        />
      ),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button onClick={onClick} ghost circle disabled={categoryId === 'Loading'}>
      <div className="text-xl p-0.5" title={iconTitle}>
        {getCategoryIcon(categoryId)}
      </div>
    </Button>
  );
}
