'use client';
import { useCallback, useEffect } from 'react';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { isAndroidWebInterfaceAvailable } from '@reshima/shared-ui';
import { useClientAuth } from '../client-auth';

declare global {
  interface Window {
    AndroidSignInWithCustomToken?: (customToken: string) => Promise<void>;
  }
}

export function useAndroidToWebViewAuthSync({
  onSignIn,
}: {
  onSignIn: () => void;
}) {
  const name = 'UseAndroidToWebViewAuthSync';

  const { signInWithCustomToken } = useClientAuth();

  const androidSignInWithCustomToken = useCallback(
    async (customToken: string) => {
      const action = Action.SignIn;

      const properties = {
        customTokenLength: customToken.length,
      };

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
        properties,
      });

      try {
        await signInWithCustomToken({ customToken });

        window.AndroidWebInterface.signInWithCustomTokenResponse(true);

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          start,
        });

        onSignIn();
      } catch (error) {
        trackException({
          name,
          action,
          error,
          start,
        });
        window.AndroidWebInterface.signInWithCustomTokenResponse(false);
      }
    },
    [onSignIn, signInWithCustomToken],
  );

  useEffect(() => {
    if (isAndroidWebInterfaceAvailable()) {
      window.AndroidSignInWithCustomToken = androidSignInWithCustomToken;
      window.AndroidWebInterface.signInWithCustomTokenReady();
    }
    return () => {
      window.AndroidSignInWithCustomToken = undefined;
    };
  }, [androidSignInWithCustomToken]);
}
