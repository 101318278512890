'use client';
import { ReactNode, useState } from 'react';
import { GoChevronDown } from 'react-icons/go';
import { Dropdown, Placement } from './dropdown';
import classNames from 'classnames';
import { Button } from './button';
import { StopClickPropagation } from './stop-click-propagation';

export type SelectOption<T> = {
  value: T;
  shortLabel?: ReactNode;
  longLabel: ReactNode;
  text?: string;
};

type Props<T> = {
  ariaLabel: string;
  value?: T;
  placeholder?: ReactNode;
  options: SelectOption<T>[];
  buttonClassName?: string;
  contentClassName?: string;
  optionsClassName?: string;
  optionClassName?: string;
  ghost?: boolean;
  tight?: boolean;
  disabled?: boolean;
  round?: boolean;
  chevron?: boolean;
  portal?: boolean;
  placement: Placement;
  search?: boolean;
  secondary?: boolean;
  searchPlaceholder?: string;
  noResultsMessage?: ReactNode;
  onChange: (value: T) => void;
};

type SearchContentProps<T> = Pick<
  Props<T>,
  | 'value'
  | 'options'
  | 'search'
  | 'searchPlaceholder'
  | 'contentClassName'
  | 'optionsClassName'
  | 'optionClassName'
  | 'noResultsMessage'
  | 'onChange'
>;

function SelectContent<T>({
  value,
  options,
  search,
  searchPlaceholder,
  contentClassName,
  optionsClassName,
  optionClassName,
  noResultsMessage,
  onChange,
}: SearchContentProps<T>) {
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions =
    search && searchValue
      ? options.filter((option) => option.text?.includes(searchValue))
      : options;

  return (
    <div className={classNames('flex flex-col gap-2', contentClassName)}>
      {search && (
        <StopClickPropagation className="w-full">
          <input
            className="w-full px-2 py-1 bg-base-100 border border-base-300 rounded-md"
            placeholder={searchPlaceholder}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            autoFocus
          />
        </StopClickPropagation>
      )}
      <div className={classNames('flex flex-col', optionsClassName)}>
        {filteredOptions.length ? (
          filteredOptions.map((option, index) => {
            const isSelected = option.value === value;
            return (
              <Button
                ghost
                className={classNames(
                  'text-right',
                  {
                    'font-semibold bg-base-200': isSelected,
                  },
                  optionClassName,
                )}
                key={index}
                onClick={() => {
                  if (!isSelected) {
                    onChange(option.value);
                  }
                }}
              >
                {option.longLabel}
              </Button>
            );
          })
        ) : (
          <StopClickPropagation>
            <div className="py-0.5 xs:py-1 px-2 xs:px-3">
              {noResultsMessage}
            </div>
          </StopClickPropagation>
        )}
      </div>
    </div>
  );
}

export function Select<T>({
  ariaLabel,
  value,
  placeholder,
  options,
  buttonClassName,
  contentClassName,
  optionsClassName,
  optionClassName,
  ghost,
  tight,
  disabled,
  round,
  chevron = true,
  portal,
  placement,
  search,
  secondary,
  searchPlaceholder,
  noResultsMessage,
  onChange,
}: Props<T>) {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Dropdown
      ariaLabel={ariaLabel}
      ghost={ghost}
      tight={tight}
      disabled={disabled}
      round={round}
      secondary={secondary}
      placement={placement}
      buttonClassName={buttonClassName}
      portal={portal}
      container={
        <div className="flex items-center gap-1">
          <div>{selectedOption?.shortLabel || placeholder}</div>
          {chevron && (
            <div>
              <GoChevronDown />
            </div>
          )}
        </div>
      }
    >
      <SelectContent
        value={value}
        options={options}
        contentClassName={contentClassName}
        optionsClassName={optionsClassName}
        optionClassName={optionClassName}
        search={search}
        searchPlaceholder={searchPlaceholder}
        noResultsMessage={noResultsMessage}
        onChange={onChange}
      />
    </Dropdown>
  );
}
