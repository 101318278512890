import { ReactNode } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { useTranslations } from '@reshima/translations-ui';
import {
  Button,
  Checkbox,
  Dropdown,
  Placement,
  StopClickPropagation,
} from '@reshima/pure-ui';

type Option = {
  id: string;
  label: ReactNode;
};

type Props = {
  ids?: string[];
  options: Option[];
  disabled?: boolean;
  containerClassName?: string;
  buttonClassName?: string;
  allSelectedLabel?: string;
  selectedLabel?: string;
  ghost?: boolean;
  tight?: boolean;
  placement: Placement;
  secondary?: boolean;
  onChange: (ids?: string[]) => void;
};

export function MultiSelect({
  ids,
  options,
  disabled,
  ghost,
  tight,
  placement,
  secondary,
  allSelectedLabel,
  selectedLabel,
  containerClassName,
  buttonClassName,
  onChange,
}: Props) {
  const dictionary = useTranslations();

  const {
    selected,
    selectAll,
    ariaLabel,
    selectAriaLabel,
    defaultAllSelectedLabel,
  } = dictionary['multiselect'];

  const finalIds = ids || options.map(({ id }) => id);
  const idsMap = Object.fromEntries(finalIds.map((id) => [id]));
  const optionsMap: Record<string, Option> = Object.fromEntries(
    options.map((option) => [option.id, option]),
  );

  const isAllSelected = options.every(({ id }) => id in idsMap);

  function onSelectAllClick() {
    onChange(isAllSelected ? [] : undefined);
  }

  function getContainerLabel() {
    const selectedLabelValue = selectedLabel || selected;

    if (isAllSelected || !finalIds.length) {
      return allSelectedLabel || defaultAllSelectedLabel;
    }

    if (finalIds.length === 1) {
      return optionsMap[finalIds[0]].label;
    }

    return `${finalIds.length} ${selectedLabelValue}`;
  }

  const buttonClasses =
    'w-full flex items-center gap-2 cursor-pointer py-0.5 px-2';

  return (
    <Dropdown
      ghost={ghost}
      tight={tight}
      secondary={secondary}
      ariaLabel={ariaLabel}
      disabled={disabled}
      containerClassName={containerClassName}
      buttonClassName={buttonClassName}
      placement={placement}
      container={
        <div className="flex items-center justify-between gap-0 2xs:gap-0.5 xs:gap-2 cursor-pointer">
          {getContainerLabel()}
          <HiOutlineChevronDown className="text-lg" />
        </div>
      }
    >
      <StopClickPropagation>
        <Button ghost className={buttonClasses} onClick={onSelectAllClick}>
          <Checkbox
            checked={isAllSelected}
            ariaLabel={selectAll}
            onChange={onSelectAllClick}
          />
          <span>{selectAll}</span>
        </Button>
        {options.map(({ id }) => {
          const checked = id in idsMap;

          function onClick() {
            onChange(
              checked ? finalIds.filter((i) => i !== id) : [...finalIds, id],
            );
          }

          const { label } = optionsMap[id];

          return (
            <Button ghost key={id} className={buttonClasses} onClick={onClick}>
              <Checkbox
                checked={checked}
                ariaLabel={`${selectAriaLabel} ${label}`}
                onChange={onClick}
              />
              <div className="flex items-center gap-2 cursor-pointer">
                <span>{label}</span>
              </div>
            </Button>
          );
        })}
      </StopClickPropagation>
    </Dropdown>
  );
}
