import classNames from 'classnames';
import { PropsWithChildren, useCallback } from 'react';
import { Link } from './link';

type Props = {
  href: string;
  ariaLabel: string;
  className?: string;
  keepSearch?: boolean;
  circle?: boolean;
  ghost?: boolean;
  tight?: boolean;
  round?: boolean;
  onClick?: () => void;
};

export function ButtonLink({
  href,
  ariaLabel,
  className,
  keepSearch,
  circle,
  ghost,
  tight,
  round = true,
  onClick,
  children,
}: PropsWithChildren<Props>) {
  const padding = useCallback(() => {
    if (tight) {
      return '';
    }

    if (circle) {
      return 'p-1';
    }

    return 'py-0.5 xs:py-1 px-2 xs:px-3';
  }, [circle, tight]);

  return (
    <Link
      href={href}
      aria-label={ariaLabel}
      className={classNames(
        className,
        'inline-block',
        padding(),
        { 'rounded-full': circle },
        { 'rounded-md': !circle && round },
        ghost ? 'hover:bg-base-300' : 'bg-primary-base hover:brightness-90',
        {
          'text-primary-content': !ghost,
        },
      )}
      keepSearch={keepSearch}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
