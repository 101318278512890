export const measurementWords = [
  'משקל',
  'במשקל',
  'גרם',
  'קילו',
  'קילוגרם',
  'ליטר',
  'מיליליטר',
  'גר',
  'קג',
  'לי',
  'מל',
  'מטר',
  'סמ',
];
