import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import {
  List,
  ListItemsCompletedSortBy,
  updateListCompletedItemsSortBy,
} from '@reshima/firebase';
import { Select } from '@reshima/pure-ui';

type Props = {
  list: List;
};

export function ListItemsCompletedSortingDropdown({ list }: Props) {
  const name = 'ListItemsCompletedSortingDropdown';
  const { options, label, labels, ariaLabel } =
    useTranslations()['list-items-completed-sorting-dropdown'];

  const onChange = async (completedItemsSortBy: ListItemsCompletedSortBy) => {
    const action = Action.Change;

    const properties = {
      listId: list.id,
      previous: list.completedItemsSortBy,
      new: completedItemsSortBy,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      await updateListCompletedItemsSortBy({
        listId: list.id,
        completedItemsSortBy,
      });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  };

  return (
    <Select
      ghost
      tight
      placement="right"
      ariaLabel={ariaLabel}
      value={list.completedItemsSortBy}
      buttonClassName="py-0.5 px-1"
      onChange={onChange}
      portal
      options={[
        ListItemsCompletedSortBy.lastCheckedItem,
        ListItemsCompletedSortBy.alphabetical,
      ].map((value) => ({
        value,
        shortLabel: (
          <div className="flex flex-col text-xs">
            <div className="leading-none">{label}</div>
            <div className="leading-none">{labels[value]}</div>
          </div>
        ),
        longLabel: options[value],
      }))}
      chevron={false}
    />
  );
}
