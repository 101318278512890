import { useState } from 'react';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { leaveList, List } from '@reshima/firebase';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';

export function LeaveListModal({
  list,
  onLeave,
}: {
  list: List;
  onLeave?: () => void;
}) {
  const name = 'LeaveListModal';
  const { leaveListButton, cancelButton } = useTranslations()['leave-list'];
  const [leaving, setLeaving] = useState(false);
  const { closeModal } = useModal();

  async function onLeaveListClick() {
    const action = Action.Leave;

    const properties = { listId: list.id };

    setLeaving(true);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      await leaveList({ listId: list.id });

      onLeave?.();

      closeModal();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }

    setLeaving(false);
  }

  return (
    <div className="flex justify-center gap-2">
      <Button onClick={onLeaveListClick} disabled={leaving} danger>
        {leaveListButton}
      </Button>
      <Button onClick={closeModal}>{cancelButton}</Button>
    </div>
  );
}
