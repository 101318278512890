import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import {
  Item,
  List,
  isItemChecked,
  updateItemChecked,
} from '@reshima/firebase';
import { Checkbox } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { ItemActivityType } from '@reshima/list-activity-shared';
import { useListContext } from '../lists/list-context';

type Props = {
  list: List;
  item: Item;
  className?: string;
};

export function ListItemCheckbox({ list, item, className }: Props) {
  const name = 'ListItemCheckbox';

  const { checkAriaLabel, uncheckAriaLabel } =
    useTranslations()['list-item-checkbox'];
  const { onItemChecked, addTask } = useListContext();

  async function onItemCheckedChange({ checked }: { checked: boolean }) {
    const action = Action.Check;

    const properties = {
      listId: list.id,
      itemId: item.id,
      checked,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const { id: itemId, checkedUpdatedAt } = item;

      const redo = () =>
        updateItemChecked({
          list,
          itemId,
          checked,
        });
      const undo = () =>
        updateItemChecked({
          list,
          itemId,
          checked: !checked,
          checkedUpdatedAt,
        });

      await redo();

      addTask({
        redo,
        undo,
        activity: {
          items: [item],
          type: checked ? ItemActivityType.checked : ItemActivityType.unchecked,
        },
      });

      onItemChecked();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  const checked = isItemChecked(item);

  return (
    <Checkbox
      checked={checked}
      className={className}
      ariaLabel={`${checked ? uncheckAriaLabel : checkAriaLabel} ${item.name}`}
      onChange={({ target: { checked } }) => onItemCheckedChange({ checked })}
    />
  );
}
