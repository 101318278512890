import { memo, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ensureItemAtIndex } from '@reshima/shared';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { Item, List } from '@reshima/firebase';
import { ListItem } from './list-item';
import { useListContext } from '../lists/list-context';

type FocusedItem = {
  id: string;
  index: number;
};

function getListItemHeight({
  showingItemDetails,
}: {
  showingItemDetails: boolean;
}) {
  const rootFontSize = +window
    .getComputedStyle(document.body)
    .getPropertyValue('font-size')
    .replace('px', '');

  const wrapperBorders = 2;
  const wrapperPadding = 0.5 * rootFontSize;

  const textAreaBorders = 2;
  const textAreaPadding = 0.5 * rootFontSize;
  const textAreaLineHeight = 1.5 * rootFontSize;

  const showingItemDetailsHeight = showingItemDetails ? 1.25 * rootFontSize : 0;

  return (
    wrapperBorders +
    wrapperPadding +
    textAreaBorders +
    textAreaPadding +
    textAreaLineHeight +
    showingItemDetailsHeight
  );
}

const MemoListItem = memo(ListItem, (prevProps, nextProps) => {
  if (prevProps.list.id !== nextProps.list.id) {
    return false;
  }

  const itemsProps: (keyof Item)[] = [
    'id',
    'name',
    'checked',
    'categoryId',
    'unit',
    'count',
  ];

  for (const key of itemsProps) {
    if (prevProps.item[key] !== nextProps.item[key]) {
      return false;
    }
  }

  return true;
});

type Props = {
  list: List;
  items: Item[];
};

export function ListItemsTable({ list, items }: Props) {
  const listRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Item[]>(null);
  const [focusedItem, setFocusedItem] = useState<FocusedItem>();
  const { showingItemDetails } = useListContext();

  const estimateSize = useMemo(() => {
    const size = getListItemHeight({ showingItemDetails });
    return () => size;
  }, [showingItemDetails]);

  const virtualizer = useWindowVirtualizer({
    count: items.length,
    estimateSize,
    overscan: 5,
    scrollMargin: listRef.current?.offsetTop || 0,
  });

  if (items.length === 0) {
    return null;
  }

  const updatedItems = focusedItem
    ? ensureItemAtIndex<Item>({
        items,
        idField: 'id',
        idValue: focusedItem.id,
        index: focusedItem.index,
      })
    : items;

  itemsRef.current = updatedItems;

  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={listRef}
      className="relative w-full flex flex-col"
      style={{
        height: `${virtualizer.getTotalSize()}px`,
      }}
    >
      <div
        className="absolute w-full"
        style={{
          transform: `translateY(${virtualItems[0]?.start - virtualizer.options.scrollMargin}px)`,
        }}
      >
        {virtualItems.map(({ index }) => {
          const item = updatedItems[index];
          const { id } = item;
          return (
            <div
              ref={virtualizer.measureElement}
              data-index={index}
              key={id}
              className={classNames(
                'w-full',
                'py-1',
                'border-b border-b-base-300 last:border-b-0',
                'border-t border-t-transparent',
              )}
            >
              <MemoListItem
                list={list}
                item={item}
                onFocus={() => {
                  if (!itemsRef.current) return;

                  const index = itemsRef.current.findIndex(
                    (item) => item.id === id,
                  );

                  if (index !== -1) {
                    setFocusedItem({ id, index });
                  }
                }}
                onBlur={() => {
                  setFocusedItem(undefined);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
