import { initializeTelemetry } from './initialize-telemetry';
import { initializeFirebase } from './initialize-firebase';
import { initializeClientServer } from './initialize-client-server';

export default async function initializer() {
  await initializeTelemetry();

  if (typeof window !== 'undefined') {
    initializeFirebase();
    initializeClientServer();
  }
}
