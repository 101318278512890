import { AiOutlineDelete } from 'react-icons/ai';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { DeleteListActivityModal } from './delete-list-activity-modal';

type Props = {
  listId: string;
  onDelete: () => void;
};

export function DeleteListActivityButton({ listId, onDelete }: Props) {
  const name = 'DeleteListActivityButton';
  const { heading, button } = useTranslations()['delete-list-activity'];
  const { showModal } = useModal();

  const properties = { listId };

  function onDeleteButtonClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      content: <DeleteListActivityModal listId={listId} onDelete={onDelete} />,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button
      className="flex items-center gap-2"
      onClick={onDeleteButtonClick}
      ghost
    >
      <AiOutlineDelete className="text-lg" />
      {button}
    </Button>
  );
}
