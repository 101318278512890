'use client';
import classNames from 'classnames';
import { FiMoon, FiSun } from 'react-icons/fi';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useTheme } from './context';

type Props = {
  className?: string;
  label?: boolean;
  round?: boolean;
};

export function ThemeToggler({ className, label, round }: Props) {
  const name = 'ThemeToggler';
  const { theme, setTheme } = useTheme();
  const { ariaLabel, light, dark } = useTranslations()['theme'];

  function onThemeClick() {
    const action = Action.Click;
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    const properties = { oldTheme: theme, newTheme };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      if (!setTheme) {
        throw new Error('setTheme is not defined');
      }

      setTheme(newTheme);

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  return (
    <Button
      onClick={onThemeClick}
      className={classNames(
        'flex gap-2 items-center',
        { 'p-2': !label },
        className,
      )}
      ariaLabel={ariaLabel}
      ghost
      circle={!label}
      round={round}
    >
      <FiMoon className="text-xl hidden dark:block" />
      <FiSun className="text-xl dark:hidden" />
      {label && (
        <>
          <span className="hidden dark:block">{light}</span>
          <span className="dark:hidden">{dark}</span>
        </>
      )}
    </Button>
  );
}
