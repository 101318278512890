import { AiOutlineDelete } from 'react-icons/ai';
import { Button } from '@reshima/pure-ui';
import { Item, List } from '@reshima/firebase';
import { useTranslations } from '@reshima/translations-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { DeleteCompletedItemsModal } from './delete-completed-items-modal';
import { useListContext } from '../lists/list-context';

export function DeleteCompletedItemsButton({
  list,
  completedItems,
}: {
  list: List;
  completedItems: Item[];
}) {
  const name = 'DeleteCompletedItemsButton';
  const { button, heading, description } =
    useTranslations()['delete-completed-items'];
  const properties = { listId: list.id };

  const { showModal } = useModal();
  const { addTask } = useListContext();

  function onDeleteButtonClick() {
    const action = Action.Click;

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      description,
      content: (
        <DeleteCompletedItemsModal
          list={list}
          completedItems={completedItems}
          addTask={addTask}
        />
      ),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  }

  return (
    <Button
      className="flex items-center gap-1"
      onClick={onDeleteButtonClick}
      ghost
    >
      <AiOutlineDelete className="text-lg" />
      {button}
    </Button>
  );
}
