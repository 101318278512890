import classNames from 'classnames';
import { Button } from '@reshima/pure-ui';
import { GrRedo, GrUndo } from 'react-icons/gr';
import { useListContext } from '../lists/list-context';

type Props = {
  className?: string;
};

export function RevertingBanner({ className }: Props) {
  const { onUndo, onRedo, containsRedoTasks, containsUndoTasks } =
    useListContext();

  if (!containsRedoTasks && !containsUndoTasks) {
    return null;
  }

  return (
    <div
      className={classNames(
        'flex items-center justify-center gap-1 2xs:gap-2 xs:gap-4',
        className,
      )}
    >
      <Button onClick={onUndo} disabled={!containsUndoTasks} ghost circle>
        <div className="p-1.5">
          <GrRedo />
        </div>
      </Button>
      <Button onClick={onRedo} disabled={!containsRedoTasks} ghost circle>
        <div className="p-1.5">
          <GrUndo />
        </div>
      </Button>
    </div>
  );
}
