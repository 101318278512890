export async function asyncRetry<T>({
  fn,
  retries,
}: {
  fn: () => Promise<T>;
  retries: number;
}): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) {
      throw error;
    }

    return asyncRetry({ fn, retries: retries - 1 });
  }
}
