'use client';
import { useCallback, useEffect, useState } from 'react';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useClientAuth } from '@reshima/client-auth-ui';
import { useModal } from '@reshima/modals-ui';
import { useTranslations } from '@reshima/translations-ui';
import { AppUser, updateUserMessageShownCount } from '@reshima/firebase';
import { daysDiff } from '@reshima/shared';
import {
  isIOSOrAndroid as _isIOSOrAndroid,
  isShareAvailable as _isShareAvailable,
} from '@reshima/shared-ui';
import { ReferralModal } from './referral-modal';
import { getReferralUserMessage } from './referral-local-storage';

function _isVeteranUser(user: AppUser): boolean {
  const creationTime = user?.firebaseUser?.metadata?.creationTime;

  if (!creationTime) {
    return false;
  }

  const now = new Date();
  const creationDate = new Date(creationTime);

  const days = daysDiff(now, creationDate);

  return days > 60;
}

export function useReferral() {
  const name = 'useReferral';
  const { heading } = useTranslations()['referral-modal'];
  const { user, userData } = useClientAuth();
  const { showModal, isModalOpen } = useModal();
  const [isShown, setIsShown] = useState(false);

  const main = useCallback(async () => {
    if (isShown || !userData || !user || isModalOpen) {
      return;
    }

    const isIOSOrAndroid = _isIOSOrAndroid();
    const isShareAvailable = _isShareAvailable();
    const isVeteranUser = _isVeteranUser(user);
    const fireStoreHiddenAt = userData?.messages?.referral?.hiddenAt;
    const localStorageHiddenAt = getReferralUserMessage()?.hiddenAt;
    const isHiddenAt = !!(fireStoreHiddenAt || localStorageHiddenAt);
    const shownCount = userData?.messages?.referral?.shownCount || 0;
    const isShownCountExceeded = shownCount > 1;

    const shouldShow =
      isIOSOrAndroid &&
      isShareAvailable &&
      isVeteranUser &&
      !isHiddenAt &&
      !isShownCountExceeded;

    const properties = {
      shownCount,
      isShownCountExceeded,
      isIOSOrAndroid,
      isShareAvailable,
      isVeteranUser,
      fireStoreHiddenAt,
      localStorageHiddenAt,
      isHiddenAt,
      shouldShow,
    };

    if (!shouldShow) {
      trackEvent({
        name,
        action: Action.Initialize,
        actionModifier: ActionModifier.Skip,
        properties,
      });

      return;
    }

    const start = trackEvent({
      name,
      action: Action.Initialize,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      setIsShown(true);

      const userId = user.firebaseUser.uid;

      await updateUserMessageShownCount({ userId, messageId: 'referral' });

      showModal({
        heading,
        content: <ReferralModal userId={userId} />,
      });

      trackEvent({
        name,
        action: Action.Initialize,
        actionModifier: ActionModifier.End,
        start,
        properties,
      });
    } catch (error) {
      trackException({
        name,
        action: Action.Initialize,
        start,
        properties,
        error,
      });
    }
  }, [userData, isModalOpen, showModal, heading, user, isShown]);

  useEffect(() => {
    main();
  }, [main]);
}
