'use client';
import { useCallback } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { useNavigate } from '@reshima/navigation-ui';
import { useAndroidToWebViewAuthSync } from './useAndroidToWebViewAuthSync';

export function AndroidSignIn() {
  const navigate = useNavigate();

  const onSignIn = useCallback(() => {
    navigate('/lists');
  }, [navigate]);

  useAndroidToWebViewAuthSync({ onSignIn });

  return <AiOutlineLoading className="text-5xl animate-spin" />;
}
