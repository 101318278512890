import { PropsWithChildren } from 'react';
import classNames from 'classnames';

type Props = PropsWithChildren<{
  className?: string;
}>;

export function Heading({ className, children }: Props) {
  return (
    <h1
      className={classNames(
        'text-xl font-semibold flex justify-center text-center',
        className,
      )}
    >
      {children}
    </h1>
  );
}
