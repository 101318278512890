import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { updateUserMessageHiddenAt } from '@reshima/firebase';
import { ShareButton } from '@reshima/shared-ui';
import { setReferralUserMessageHiddenAt } from './referral-local-storage';

type Props = {
  userId: string;
};

export function ReferralModal({ userId }: Props) {
  const name = 'ReferralModal';
  const { doNotShowAgain, paragraphs } = useTranslations()['referral-modal'];
  const { closeModal } = useModal();

  async function onDoNotShowAgainShowAgainClick() {
    const start = trackEvent({
      name,
      action: Action.DoNotShowAgain,
      actionModifier: ActionModifier.Start,
    });

    try {
      setReferralUserMessageHiddenAt();

      await updateUserMessageHiddenAt({ userId, messageId: 'referral' });
    } catch (error) {
      trackException({
        name,
        action: Action.DoNotShowAgain,
        error,
        start,
      });
    } finally {
      closeModal();
    }
  }

  return (
    <div className="flex flex-col justify-center gap-2">
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="text-center">
          {paragraph}
        </p>
      ))}
      <div className="flex justify-center gap-2">
        <ShareButton
          shareData={{ url: 'https://reshima.co.il/mobile', title: 'Reshima' }}
        />
        <Button onClick={onDoNotShowAgainShowAgainClick} secondary>
          {doNotShowAgain}
        </Button>
      </div>
    </div>
  );
}
