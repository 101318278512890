import classNames from 'classnames';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { HiOutlineRefresh } from 'react-icons/hi';
import { useIsOnline } from '@reshima/shared-ui';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { useSyncStatus } from './sync-status';
import { SyncStatus } from './models';

type Props = {
  className?: string;
};

export function SyncStatusIndicator({ className }: Props) {
  const { syncingAriaLabel, syncedAriaLabel } =
    useTranslations()['account-menu'];
  const { userLoading, isSigningIn } = useClientAuth();
  const { isOnline } = useIsOnline();
  const { syncStatus } = useSyncStatus();

  if (!isOnline || userLoading || isSigningIn) return null;

  return (
    <div className={classNames('bg-base-100 rounded-full', className)}>
      {syncStatus === SyncStatus.Syncing && (
        <HiOutlineRefresh
          aria-label={syncingAriaLabel}
          className="text-sm text-blue-500 animate-spin"
        />
      )}
      {syncStatus === SyncStatus.Synced && (
        <IoCheckmarkCircleOutline
          aria-label={syncedAriaLabel}
          className="text-sm text-primary-base"
        />
      )}
    </div>
  );
}
