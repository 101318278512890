import { backendRoutes, getBackendURL } from '@reshima/client-server';
import {
  ListActivityPostRequestPayload,
  ListActivityQuery,
  NewListItemActivityV2,
  QueryListItemActivityResponse,
} from '@reshima/list-activity-shared';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';

const {
  listActivity: { path },
} = backendRoutes;

export async function postListActivityItems({
  token,
  listId,
  activities,
}: {
  token: string;
  listId: string;
  activities: NewListItemActivityV2[];
}): Promise<void> {
  const name = 'PostListActivityItems';
  const action = Action.Post;

  const properties = {
    listId,
    activitiesLength: activities.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const url = `${getBackendURL()}${path}/${listId}`;

    const payload: ListActivityPostRequestPayload = { activities };

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to post list activity item: ${response.statusText}`,
      );
    }

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      properties,
      start,
      error,
    });
    throw error;
  }
}

export async function getListActivityItems({
  token,
  query,
}: {
  token: string;
  query: ListActivityQuery;
}): Promise<QueryListItemActivityResponse> {
  const { listId, ...filters } = query;
  const url = `${getBackendURL()}${path}/${listId}`;

  const params = new URLSearchParams();

  Object.entries(filters)
    .filter(([, value]) => value)
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => params.append(`${key}[]`, v));
      } else {
        params.append(key, value.toString());
      }
    });

  const response = await fetch(`${url}?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to get list activity items: ${response.statusText}`,
    );
  }

  return response.json();
}

export async function deleteAllActivityItems({
  token,
  listId,
}: {
  token: string;
  listId: string;
}): Promise<void> {
  const url = `${getBackendURL()}${path}/${listId}`;

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to delete all list activity items: ${response.statusText}`,
    );
  }
}
