import { useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { Item, List, getItems, isItemChecked } from '@reshima/firebase';
import { Button, CheckboxesList, Select } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { getCategoryIcon } from '@reshima/category';

// Adding Byte Order Mark (BOM) for UTF-8
const BOM = '\uFEFF';

enum ItemColumn {
  name = 'name',
  category = 'category',
  status = 'status',
}

enum FilteredItems {
  all = 'all',
  checked = 'checked',
  unchecked = 'unchecked',
}

enum SortOrder {
  alphabetical = 'alphabetical',
  category = 'category',
}

export function DownloadListModal({ list }: { list: List }) {
  const name = 'DownloadListModal';
  const [columns, setColumns] = useState<ItemColumn[]>(
    Object.values(ItemColumn),
  );
  const [filteredItems, setFilteredItems] = useState<FilteredItems>(
    FilteredItems.all,
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.alphabetical);
  const [downloading, setDownloading] = useState(false);
  const dictionary = useTranslations();
  const {
    categories,
    'export-list': {
      button,
      cancelButton,
      columnsLabel,
      columnsLabels,
      filteredItemsLabels,
      filteredItemsAriaLabel,
      filteredItemsLabel,
      sortByLabel,
      sortByAriaLabel,
      sortByLabels,
    },
  } = dictionary;
  const { closeModal } = useModal();

  const columnsOptions = Object.values(ItemColumn).map((value) => ({
    id: value,
    label: columnsLabels[value],
    ariaLabel: columnsLabels[value],
  }));

  const filteredItemsOptions = Object.values(FilteredItems).map((value) => ({
    value,
    shortLabel: filteredItemsLabels[value],
    longLabel: filteredItemsLabels[value],
  }));

  const sortByOptions = Object.values(SortOrder).map((value) => ({
    value,
    shortLabel: sortByLabels[value],
    longLabel: sortByLabels[value],
  }));

  async function onDownloadClick() {
    const action = Action.Click;
    const properties = { listId: list.id };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    setDownloading(true);

    try {
      const items = await getItems({ listId: list.id });

      const header = columns
        .map((column) => columnsLabels[column])
        .map((value) => `"${value}"`)
        .join(',');

      const columnRender: Record<ItemColumn, (item: Item) => string> = {
        [ItemColumn.name]: ({ name }) => name,
        [ItemColumn.category]: ({ categoryId }) => {
          const icon = getCategoryIcon(categoryId);
          const label = categories[categoryId];
          return `${icon} ${label}`;
        },
        [ItemColumn.status]: (item) => (isItemChecked(item) ? 'X' : ''),
      };

      const itemsFiltering: Record<FilteredItems, (item: Item) => boolean> = {
        [FilteredItems.all]: () => true,
        [FilteredItems.checked]: isItemChecked,
        [FilteredItems.unchecked]: (item) => !isItemChecked(item),
      };

      const itemsSorting: Record<SortOrder, (a: Item, b: Item) => number> = {
        [SortOrder.alphabetical]: (a, b) => a.name.localeCompare(b.name),
        [SortOrder.category]: (a, b) =>
          a.categoryId.localeCompare(b.categoryId),
      };

      const csv = [header]
        .concat(
          items
            .filter(itemsFiltering[filteredItems])
            .sort(itemsSorting[sortOrder])
            .map((item) =>
              columns
                .map((column) => columnRender[column](item))
                .map((value) => `"${value}"`)
                .join(','),
            ),
        )
        .join('\n');

      const blob = new Blob([BOM + csv], { type: 'text/csv' });

      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'items.csv';

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties: {
          ...properties,
          itemsCount: items.length,
        },
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }

    setDownloading(false);
  }

  return (
    <div className="flex flex-col gap-3">
      <CheckboxesList
        ids={columns}
        label={<div className="font-semibold">{columnsLabel}</div>}
        options={columnsOptions}
        onChange={(columns) => setColumns(columns)}
      />
      <div>
        <div className="font-semibold">{filteredItemsLabel}</div>
        <Select
          placement="left"
          value={filteredItems}
          onChange={setFilteredItems}
          options={filteredItemsOptions}
          ariaLabel={filteredItemsAriaLabel}
          secondary
        />
      </div>
      <div>
        <div className="font-semibold">{sortByLabel}</div>
        <Select
          placement="left"
          value={sortOrder}
          onChange={setSortOrder}
          options={sortByOptions}
          ariaLabel={sortByAriaLabel}
          secondary
        />
      </div>
      <div className="flex justify-center gap-2">
        <Button
          className="flex items-center gap-2"
          onClick={onDownloadClick}
          disabled={downloading || !columns.length}
        >
          <AiOutlineDownload className="text-lg" />
          {button}
        </Button>
        <Button onClick={closeModal} secondary>
          {cancelButton}
        </Button>
      </div>
    </div>
  );
}
