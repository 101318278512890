'use client';
import { useCallback, useEffect } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { ProviderId, TestProviderId } from '@reshima/shared';
import { useTranslations } from '@reshima/translations-ui';
import { useNavigate } from '@reshima/navigation-ui';
import { SignInButton } from './sign-in-button';
import { useClientAuth } from './client-auth';

type Props = {
  navigateOnSignInRoute?: string;
};

export function SignIn({ navigateOnSignInRoute }: Props) {
  const name = 'SignIn';

  const { error } = useTranslations()['sign-in'];
  const {
    user,
    userLoading,
    isSigningIn,
    isSigningInError,
    signingInProviderId,
    testUserEmail,
    signIn,
  } = useClientAuth();
  const navigate = useNavigate();

  const onClick = useCallback(
    async (
      params:
        | { providerId: ProviderId }
        | { providerId: TestProviderId; email: string },
    ) => {
      const action = Action.Click;

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
      });

      try {
        await signIn(params);

        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.End,
          start,
        });
      } catch (error) {
        trackException({
          name,
          action,
          start,
          error,
        });
      }
    },
    [signIn],
  );

  useEffect(() => {
    if (navigateOnSignInRoute && user?.isSignedIn) {
      navigate(navigateOnSignInRoute);
    }
  }, [navigate, navigateOnSignInRoute, user]);

  return (
    <div className="flex justify-center">
      {userLoading ? (
        <AiOutlineLoading className="text-3xl animate-spin" />
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            {testUserEmail ? (
              <SignInButton
                isSignedId={false}
                isSigningIn={signingInProviderId === TestProviderId.test}
                disabled={userLoading || isSigningIn}
                providerId={TestProviderId.test}
                onClick={() =>
                  onClick({
                    providerId: TestProviderId.test,
                    email: testUserEmail,
                  })
                }
              />
            ) : (
              Object.values(ProviderId).map((providerId) => (
                <SignInButton
                  key={providerId}
                  isSignedId={!!user?.providers[providerId]}
                  isSigningIn={signingInProviderId === providerId}
                  disabled={userLoading || isSigningIn}
                  providerId={providerId}
                  onClick={() => onClick({ providerId })}
                />
              ))
            )}
          </div>
          {isSigningInError && <div className="text-red-500">{error}</div>}
        </div>
      )}
    </div>
  );
}
