import { HiOutlineDotsVertical } from 'react-icons/hi';
import { Item, List } from '@reshima/firebase';
import { Dropdown } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import { DeleteCompletedItemsButton } from './delete-completed-items-button';
import { ResetCompletedItemsButton } from './reset-completed-items-button';

export function ListItemsCompletedActionsDropdown({
  list,
  completedItems,
}: {
  list: List;
  completedItems: Item[];
}) {
  const { label, ariaLabel } =
    useTranslations()['list-items-completed-actions-dropdown'];

  return (
    <Dropdown
      ghost
      ariaLabel={ariaLabel}
      placement="left"
      tight
      buttonClassName="py-1 px-0.5"
      portal
      container={
        <div className="flex items-center">
          <HiOutlineDotsVertical className="text-lg" />
          <div className="text-sm">{label}</div>
        </div>
      }
    >
      <div className="flex flex-col items-stretch">
        <ResetCompletedItemsButton
          list={list}
          completedItems={completedItems}
        />
        <DeleteCompletedItemsButton
          list={list}
          completedItems={completedItems}
        />
      </div>
    </Dropdown>
  );
}
