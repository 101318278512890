import { RxCaretSort } from 'react-icons/rx';
import { Reorder, useDragControls } from 'framer-motion';
import { CategoryId } from '@reshima/category';
import { CategoryLabelIcon } from './category-label-icon';

function Category({ categoryId }: { categoryId: CategoryId }) {
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      value={categoryId}
      key={categoryId}
      dragListener={false}
      dragControls={dragControls}
      transition={{ duration: 0.2 }}
    >
      <div className="flex items-center bg-base-100 border-b border-base-300 last:border-b-0">
        <div
          className="cursor-grab touch-none p-2"
          onPointerDown={(event) => {
            event.preventDefault();
            dragControls.start(event);
          }}
        >
          <RxCaretSort className="text-xl" />
        </div>
        <CategoryLabelIcon categoryId={categoryId} className="flex-1" />
      </div>
    </Reorder.Item>
  );
}

export function CategoriesSorter({
  categories,
  onCategoriesChange,
}: {
  categories: CategoryId[];
  onCategoriesChange: (categories: CategoryId[]) => void;
}) {
  return (
    <div className="w-full flex flex-col max-h-[60vh] overflow-y-auto p-1">
      <Reorder.Group
        axis="y"
        values={categories}
        onReorder={onCategoriesChange}
      >
        {categories.map((categoryId) => (
          <Category key={categoryId} categoryId={categoryId} />
        ))}
      </Reorder.Group>
    </div>
  );
}
