'use client';
import { memo } from 'react';
import { HiUserPlus } from 'react-icons/hi2';
import { useTranslations } from '@reshima/translations-ui';
import { useClientAuth } from '@reshima/client-auth-ui';
import { Action, ActionModifier, trackEvent } from '@reshima/telemetry';
import { List } from '@reshima/firebase';
import { useModal } from '@reshima/modals-ui';
import { Button } from '@reshima/pure-ui';
import { ShareListLinkModal } from './share-list-link-modal';

export const ShareListLink = memo(function ShareListLink({
  list,
}: {
  list: List;
}) {
  const name = 'ShareListLink';

  const { buttonText, buttonTooltip, heading } =
    useTranslations()['share-list'];

  const { showModal } = useModal();
  const { user } = useClientAuth();

  const properties = { listId: list.id };

  function onClick() {
    const action = Action.Click;

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    showModal({
      heading,
      content: <ShareListLinkModal list={list} />,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
    });
  }

  return (
    <Button onClick={onClick} title={buttonTooltip} ghost disabled={!user}>
      <div className="flex items-center gap-2">
        <HiUserPlus className="text-lg" />
        {buttonText}
      </div>
    </Button>
  );
});
