import { Item, List, ListSortBy } from '@reshima/firebase';
import { ListItemsTableFixedCategorized } from './list-items-table-fixed-categorized';
import { ListItemsTableManual } from './list-items-table-manual';
import { ListItemsTableChecked } from './list-items-table-checked';
import { ListItemsTableName } from './list-items-table-name';

const components: Record<
  ListSortBy,
  React.ComponentType<{
    list: List;
    items: Item[];
  }>
> = {
  [ListSortBy.manual]: ListItemsTableManual,
  [ListSortBy.lastCreatedItem]: ListItemsTableChecked,
  [ListSortBy.alphabetical]: ListItemsTableName,
  [ListSortBy.fixedCategories]: ListItemsTableFixedCategorized,
};

export function SortedListItems({
  list,
  items,
}: {
  list: List;
  items: Item[];
}) {
  const { sortBy } = list;
  const Component = components[sortBy] || ListItemsTableFixedCategorized;
  return <Component list={list} items={items} />;
}
