import { ReactElement } from 'react';
import { GoSortAsc, GoSortDesc } from 'react-icons/go';
import { useTranslations } from '@reshima/translations-ui';
import {
  List,
  ListSortByDirection,
  updateListCompletedItemsSortByDirection,
} from '@reshima/firebase';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { Button } from '@reshima/pure-ui';

type Props = {
  list: List;
};

export function ListItemsCompletedSortingDirection({ list }: Props) {
  const name = 'ListItemsCompletedSortingDirection';
  const { ascAriaLabel, descAriaLabel } =
    useTranslations()['sorting-direction'];

  const states: Record<
    ListSortByDirection,
    {
      ariaLabel: string;
      icon: ReactElement;
      nextDirection: ListSortByDirection;
    }
  > = {
    [ListSortByDirection.asc]: {
      ariaLabel: ascAriaLabel,
      icon: <GoSortDesc />,
      nextDirection: ListSortByDirection.desc,
    },
    [ListSortByDirection.desc]: {
      ariaLabel: descAriaLabel,
      icon: <GoSortAsc />,
      nextDirection: ListSortByDirection.asc,
    },
  };

  const { ariaLabel, icon, nextDirection } =
    states[list.completedItemsSortByDirection];

  const onChange = async (
    completedItemsSortByDirection: ListSortByDirection,
  ) => {
    const action = Action.Change;

    const properties = {
      listId: list.id,
      previous: list.completedItemsSortByDirection,
      new: completedItemsSortByDirection,
    };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      await updateListCompletedItemsSortByDirection({
        listId: list.id,
        completedItemsSortByDirection,
      });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  };

  return (
    <Button
      ghost
      tight
      ariaLabel={ariaLabel}
      className="text-xl py-0.5 px-0.5"
      onClick={() => onChange(nextDirection)}
    >
      {icon}
    </Button>
  );
}
