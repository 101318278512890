import { useState } from 'react';
import { Item, List, checkItems } from '@reshima/firebase';
import { Button } from '@reshima/pure-ui';
import { useTranslations } from '@reshima/translations-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useModal } from '@reshima/modals-ui';
import { ItemActivityType } from '@reshima/list-activity-shared';
import { ListContext } from '../lists/list-context';

export function ResetCompletedItemsModal({
  list,
  completedItems,
  addTask,
}: {
  list: List;
  completedItems: Item[];
  addTask: ListContext['addTask'];
}) {
  const name = 'ResetCompletedItemsModal';
  const { resetListButton, cancelButton } =
    useTranslations()['reset-completed-items'];
  const properties = { listId: list.id };

  const [resetting, setResetting] = useState(false);
  const { closeModal } = useModal();

  async function onResetClick() {
    const action = Action.Reset;

    setResetting(true);

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      const itemsCheckedUpdatedAtMap = Object.fromEntries(
        completedItems.map(({ id, checkedUpdatedAt }) => [
          id,
          checkedUpdatedAt,
        ]),
      );
      const redo = () =>
        checkItems({
          list,
          items: completedItems,
          checked: false,
        });
      const undo = () =>
        checkItems({
          list,
          items: completedItems,
          checked: true,
          itemsCheckedUpdatedAtMap,
        });

      await redo();

      addTask({
        redo,
        undo,
        activity: {
          items: completedItems,
          type: ItemActivityType.unchecked,
        },
      });

      closeModal();

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }

    setResetting(false);
  }
  return (
    <div className="flex justify-center gap-2">
      <Button onClick={onResetClick} disabled={resetting} danger>
        {resetListButton}
      </Button>
      <Button onClick={closeModal}>{cancelButton}</Button>
    </div>
  );
}
