import { removePrefixLetter } from './remove-letter';

export function removeToLetter({
  word,
  inflectionsMap,
  wordsMap,
}: {
  word: string;
  inflectionsMap: Record<string, unknown>;
  wordsMap: Record<string, unknown>;
}): string {
  return removePrefixLetter({
    word,
    letter: 'ל',
    inflectionsMap,
    wordsMap,
  });
}
